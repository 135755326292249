import {Container} from "@mui/material";
import React, {FunctionComponent, useEffect, useState} from "react";
import {authenticationService, User, UserContext} from "../services/authentication-service";
import TopBar from "./TopBar";

export const Layout: FunctionComponent = (props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  useEffect(() => {
    authenticationService.currentUser.subscribe((user) => {
      setCurrentUser(user);
    });
    return () => {
      // unsub here
    };
  }, []);
  return (
    <div>
      <UserContext.Provider value={currentUser}>
        <TopBar />
        <Container>{props.children}</Container>
      </UserContext.Provider>
    </div>
  );
};
