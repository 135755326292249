import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState, useContext} from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useOrientation} from "../hooks/useOrientation";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, CircularProgress} from "@mui/material";

//import MobileDatePicker from "@mui/lab/MobileDatePicker";
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';

import {getRangeBookingSlots, bookRange} from "../services/user-service";
import enLocale from 'date-fns/locale/en-GB';

import RangeUsers from './RangeUsers';
import {authenticationService, UserContext} from "../services/authentication-service";

const BookRange: FunctionComponent = () => {
  
  const currentUser = useContext(UserContext);
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [maxBookingCount, setMaxBookingCount] = useState(0);
  const [rangeBookings, setRangeBookings] = useState(Object);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  const [rangeBookingSlots, setRangeBookingSlots] = React.useState<RangeBookingSlot[]>();

  interface RangeBookingSlot {
    slotId: number;
    slotTimes: string;
    currentBookings: number;
    maxBookings: number;
    shooterList: string;
  };

  interface Booking {
    slotId: string;
    booking: string;
  };

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }
    
    getRangeBookingSlots(new Intl.DateTimeFormat('en-GB').format(new Date(value as Date))).then(
      (data)=>{
        formik.values.roundCount = currentUser ? currentUser.forename : ""
        setRangeBookingSlots(data as RangeBookingSlot[])
      });
      // eslint-disable-next-line
  }, [navigate, from]);

  const validationSchema = Yup.object({
    roundCount: Yup.string().required("Shooters Names are required"),
  });

  const updateBookingSlots = () => {
    getRangeBookingSlots(new Intl.DateTimeFormat('en-GB').format(new Date(value as Date))).then(
      (data)=>{
        setRangeBookingSlots(data as RangeBookingSlot[])
      });
  }

  const onDataChange = (id: any, count: any) => {
    if(rangeBookings === undefined)
    {
      setRangeBookings({[`${id}`]:count});
    }
    else
    {
      setRangeBookings({...rangeBookings,  [`${id}`]:count});

      Object.keys(rangeBookings).forEach(
        (key) => {
          if(rangeBookings[key] > maxBookingCount)
          setMaxBookingCount(rangeBookings[key])
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      firearm: "",
      range: "",
      activity: "",
      roundCount: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);

      let bookings = new Array<Booking>();

      if(rangeBookings !== undefined)
      {
        Object.keys(rangeBookings).forEach(
          (key) => {
              if(Number(rangeBookings[key]) > 0)
                bookings.push({slotId:key, booking:rangeBookings[key]});
          }
        );
      }

      if(bookings.length)
      {
        setRangeBookings(undefined);
        setRangeBookingSlots(undefined);
        
        bookRange(new Intl.DateTimeFormat('en-GB').format(new Date(value as Date)), bookings, values.roundCount).then(
          (data)=>{
            setSubmitting(false);
            setRangeBookingSlots(data as RangeBookingSlot[])
          });
      }
      else{
        setSubmitting(false);
      }
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 1 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
            <h1 style={{textAlign: "center"}}>Book Range</h1>
            <LocalizationProvider 
            dateAdapter={AdapterDateFns} 
            //locale={enLocale}
            adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Date"
                  value={value}
                  onChange={(newValue) => {
                    newValue && setValue(newValue);
                    setRangeBookingSlots(undefined);
                    setRangeBookings(undefined);
                  }}
                  onClose={() => {
                    updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            {
              rangeBookingSlots?.length && rangeBookingSlots.map((row) => (
                <div>
                  <br/>
                  <RangeUsers id={row.slotId} currentBookings={row.currentBookings} maxBookings={row.maxBookings} slotTime={row.slotTimes} shooterList={row.shooterList} onChange={onDataChange}/>
                </div>
              ))
            }
            <TextField
              margin="normal"
              required
              fullWidth
              name="roundCount"
              label="Shooters Names"
              type="roundCount"
              id="roundCount"
              autoComplete="current-password"
              value={formik.values.roundCount}
              onChange={formik.handleChange}
              error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
              helperText={formik.touched.roundCount && formik.errors.roundCount}
            />
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Book
            </Button>
            {status?.includes('Error') && <Alert severity="error">Error: {status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            {submitting && <CircularProgress />}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BookRange;
