import React, {useState, FunctionComponent} from 'react';
import {getFirearmsLogAnnualSummary, getFirearmsLogSummary, getLogUserLookups, getRenewalRequired} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import { Label } from '@mui/icons-material';
import {authenticationService} from "../services/authentication-service";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {useFormik} from "formik";
import * as Yup from "yup";

const FirearmLogSummary: FunctionComponent<{fromAdmin?: boolean}> = (props) => {

  interface IFirearmsLogSummary {
    firearm: string;
    make: string;
    model: string;
    serialNumber: string;
    calibre: string;
    totalRounds: string;
    totalUsage: string;
};

interface ILookup {
  id: number;
  description: string;
};

  const [userLookups, setUserLookups] = useState<ILookup[]>();
  const [firearmsLogSummary, setFirearmsLogSummary] = useState<IFirearmsLogSummary[]>();
  const [firearmsLogSummary12, setFirearmsLogSummary12] = useState<IFirearmsLogSummary[]>();
  
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getRenewalRequired(Number(authenticationService.currentUserValue?.id)).then((data)=>{
      if(data.toString().toLowerCase().indexOf("true") !== -1)
        navigate("/renewal");
    });

    if(authenticationService.currentUserValue?.accessLevel && props.fromAdmin)
    {
      getLogUserLookups().then((data)=>setUserLookups(data as ILookup[]));
    }
    else
    {
      getFirearmsLogSummary().then((data)=>setFirearmsLogSummary(data as IFirearmsLogSummary[]));
      getFirearmsLogAnnualSummary().then((data)=>setFirearmsLogSummary12(data as IFirearmsLogSummary[]));
    }
  }, [navigate, from, props.fromAdmin]);

  const validationSchema = Yup.object({
    memberId: Yup.number().required("memberId is required"),
  });

  const formik = useFormik({
    initialValues: {
      memberId: -1
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
  });

  function showUserLookup() {
    return userLookups?.length && (<div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Member"
                value={formik.values.memberId}
                onChange={
                  value=>{
                    formik.setFieldValue('memberId', value.target.value); 

                    setFirearmsLogSummary(undefined);
                    setFirearmsLogSummary12(undefined);

                    getFirearmsLogSummary(value.target.value.toString()).then((data)=>setFirearmsLogSummary(data as IFirearmsLogSummary[]));
                    getFirearmsLogAnnualSummary(value.target.value.toString()).then((data)=>setFirearmsLogSummary12(data as IFirearmsLogSummary[]));
                  }
                }
                error={formik.touched.memberId && Boolean(formik.errors.memberId)}
              >
                {
                  userLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.memberId ? <div className='error'>{formik.errors.memberId}</div> : null}
            </FormControl><br/><br/>
          </div>);
  }

  return (
    <div>
      <h1 style={{textAlign: "center"}}>Firearm Log Summary</h1>
      {showUserLookup()}
      <TableContainer component={Paper} sx={{ minWidth: 300, maxWidth: 1024 }}>
      <strong>Total Summary</strong>
      <Table sx={{ minWidth: 300, maxWidth: 900 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Firearm</TableCell>
            <TableCell>Make</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>SerialNo</TableCell>
            <TableCell>Calibre</TableCell>
            <TableCell align="center">Total Rounds</TableCell>
            <TableCell align="center">Total Usage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {firearmsLogSummary && firearmsLogSummary.map((row) => (
            <TableRow
              key={row.firearm}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.firearm}</TableCell>
              <TableCell>{row.make}</TableCell>
              <TableCell>{row.model}</TableCell>
              <TableCell>{row.serialNumber}</TableCell>
              <TableCell>{row.calibre}</TableCell>
              <TableCell align="center">{row.totalRounds}</TableCell>
              <TableCell align="center">{row.totalUsage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <br/>
    <TableContainer component={Paper} sx={{ minWidth: 300, maxWidth: 1024 }}>
    <strong>Last 12 Months Summary</strong>
      <Table sx={{ minWidth: 300, maxWidth: 1024 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Firearm</TableCell>
            <TableCell>Make</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>SerialNo</TableCell>
            <TableCell>Calibre</TableCell>
            <TableCell align="center">Total Rounds</TableCell>
            <TableCell align="center">Total Usage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {firearmsLogSummary12 && firearmsLogSummary12.map((row) => (
            <TableRow
              key={"12"+row.firearm}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.firearm}</TableCell>
              <TableCell>{row.make}</TableCell>
              <TableCell>{row.model}</TableCell>
              <TableCell>{row.serialNumber}</TableCell>
              <TableCell>{row.calibre}</TableCell>
              <TableCell align="center">{row.totalRounds}</TableCell>
              <TableCell align="center">{row.totalUsage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default FirearmLogSummary;
