import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
//import {getTitleLookups} from "../services/user-service";
//import MobileDatePicker from "@mui/x-date-pickers/MobileDatePicker";
//import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { authenticationService } from "../services/authentication-service";
import { postDataAuth } from "../services/user-service";
//import { getTitleLookups } from "../services/user-service";

function FACDetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack: any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [status, setStatus] = useState<string | undefined>(undefined);

  const today = new Date();
  today.setHours(0, 0, 0, 0)

  const [initialValues, setinitialValues] = useState({
    facExpiryDate: new Date(today.getDate()-1),
    facIssueAuthority: "",
    facNumber: "",
    disabled: props.readonly,
  });

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }

  interface IFACDetails {
    id: number;
    facExpiryDate: Date,
    facIssueAuthority: string,
    facNumber: string,
  };

  const validationSchema = Yup.object({
    facNumber: Yup.string().required("Membership Number is required").min(3, "Minimum 3 digits long"),
    facIssueAuthority: Yup.string().required("Membership Type is required"),
    facExpiryDate: Yup.date()
    .min(today, "Date must be in the future")
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      facExpiryDate: initialValues.facExpiryDate,
      facIssueAuthority: initialValues.facIssueAuthority,
      facNumber: initialValues.facNumber,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {
        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: IFACDetails = {
            id: userId,
            facExpiryDate: values.facExpiryDate,
            facIssueAuthority: values.facIssueAuthority,
            facNumber: values.facNumber,
        };

        postDataAuth("AddMemberFACData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function setFormikValues(data: IFACDetails)
  {
    if(data)
    {
      setValue(data.facExpiryDate);
      setinitialValues({
        //dob: data.dob.toDateString(),
        facExpiryDate: data.facExpiryDate,
        facIssueAuthority: data.facIssueAuthority,
        facNumber: data.facNumber,
        disabled: props.readonly,
      });
    }
  }

  React.useEffect(() => {
    //if (!authenticationService.currentUserValue) {
    //  navigate(from, {replace: true});
    //}
    
    //getTitleLookups().then((data)=>{
      if(props.prePopulate)
      {
        setFormikValues(props.prePopulate as IFACDetails)
      }
    //});
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            FAC Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="facNumber"
                  label="`FAC Number"
                  name="facNumber"
                  autoComplete="facNumber"
                  disabled={formik.values.disabled}
                  value={formik.values.facNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.facNumber && Boolean(formik.errors.facNumber)}
                  helperText={formik.touched.facNumber && formik.errors.facNumber}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="facIssueAuthority"
                  label="`FAC Issue Authority"
                  name="facIssueAuthority"
                  autoComplete="facIssueAuthority"
                  disabled={formik.values.disabled}
                  value={formik.values.facIssueAuthority}
                  onChange={formik.handleChange}
                  error={formik.touched.facIssueAuthority && Boolean(formik.errors.facIssueAuthority)}
                  helperText={formik.touched.facIssueAuthority && formik.errors.facIssueAuthority}
                />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns} 
              //locale={enLocale}
              adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="FAC Expiry Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    formik.setFieldValue('facExpiryDate', newValue); 
                  }}
                  onClose={() => {
                    //updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} 
                  error={formik.touched.facExpiryDate && Boolean(formik.errors.facExpiryDate)}
                  helperText={formik.touched.facExpiryDate && formik.errors.facExpiryDate}/>}
                />
              </Stack>
            </LocalizationProvider>
            </Grid> 
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default FACDetailsPage;
