import {FunctionComponent, ReactElement, useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {UserContext} from "../services/authentication-service";

export const RequireAuth: FunctionComponent<{redirectTo: string; children: ReactElement}> = ({
  children,
  redirectTo,
}) => {
  const currentUser = useContext(UserContext);
  const location = useLocation();

  return currentUser ? children : <Navigate to={redirectTo} state={{from: location}} />;
};
