import {useFormik} from "formik";
import React, {useState} from 'react';
//import * as Yup from "yup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from "@mui/material";
import { getTitleLookups, postDataAuth } from "../services/user-service";
import { authenticationService } from "../services/authentication-service";
//import { getTitleLookups } from "../services/user-service";

function QualDetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack:any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  //const [value, setValue] = React.useState<Date | null>(new Date());
  const [cbValue, setCBValue] = React.useState<IKVP[]>();
  const [status, setStatus] = useState<string | undefined>(undefined);

  const today = new Date();
  today.setHours(0, 0, 0, 0)

  const [initialValues, setinitialValues] = useState({
    disabled: props.readonly,
  });

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }

  interface IQualDetails {
    id: number,
    qualifictionList: IKVP[] | undefined,
  };

  interface IKVP {
    key: string,
    value: string,
  };

  /*const validationSchema = Yup.object({
    cocNumber: Yup.string().required("Membership Number is required").min(3, "Minimum 3 digits long"),
    cocExpiryDate: Yup.date()
    .min(today, "Date must be in the future")
  });*/

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      disabled: initialValues.disabled,
    },
    //validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {

        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: IQualDetails = {
            id: userId,
            qualifictionList: cbValue?.slice(),
        };

        postDataAuth("AddMemberQualData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function getMapValue(findKey: string)
  {
    var retVal = false;
    cbValue?.forEach(x => {
        if(x.key === findKey)
        {
          retVal = (x.value === 'True' || x.value === 'true')
        }
      })
    /*cbValue && Object.entries(cbValue).map( ([key, value]) => 
    {
        if(key === findKey)
          retVal = value;
    });*/
    
    return retVal;
  }

  function setDefaultList(title_data: ILookup[])
  {
    const newState = title_data?.map(obj => {
        return {key:obj.id.toString(), value: "false"};
    });

    newState && setCBValue(newState as IKVP[]);
  }

  function setFormikValues(data: IQualDetails, title_data: ILookup[])
  {
    if(data)
    {
      //setValue(data.cocExpiryDate);
      //setCBValue(data.qualifictionList);
      data.qualifictionList?.length ? setCBValue(data.qualifictionList) : setDefaultList(title_data);
      setinitialValues({
        disabled: props.readonly,
      });
    }
  }

  const [lookups, setLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  React.useEffect(() => {
    getTitleLookups("GetQualificationTypeLookups").then((data)=>{
      setLookups(data as ILookup[])

      if(props.prePopulate/* && props.memberId != null*/)
      {
        //props.prePopulate(props.memberId).then((rm_data:any)=>setFormikValues(rm_data as IRegisteredMember, data as ILookup[]));
        setFormikValues(props.prePopulate as IQualDetails, data as ILookup[])
      }
    });
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);
/*
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setCBValue({ ...cbValue, Map[Number(event.target.name)]: event.target.checked });
  };*/

  const updateState = (key:string, val:boolean) => {
    const newState = cbValue?.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.key === key) {
        return {...obj, value: val.toString()};
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setCBValue(newState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setState({ ...state, [event.target.name]: event.target.checked });
    updateState(event.target.name, event.target.checked)
  };

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            Qualification Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Select Qualifiations</FormLabel>
                <FormGroup>
              {
                lookups?.length && lookups.map((row) => (
                  <FormControlLabel
                  control={
                    <Checkbox checked={getMapValue(row.id.toString())} onChange={handleChange} name={row.id.toString()} />
                  }
                  label={row.description}
                />
                ))
              }
              </FormGroup>
              <FormHelperText></FormHelperText>
              </FormControl>
              </Grid>
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default QualDetailsPage;
