import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useOrientation} from "../hooks/useOrientation";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, CircularProgress} from "@mui/material";
import {addFirearm} from "../services/user-service";
import {authenticationService} from "../services/authentication-service";

const AddFirearm: FunctionComponent = () => {
  //const {user, login} = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }
  }, [navigate, from]);

  const validationSchema = Yup.object({
    firearm: Yup.string().required("Firearm name is required"),
    make: Yup.string().required("Make is required"),
    model: Yup.string().required("Model is required"),
    calibre: Yup.string().required("Calibre is required"),
    serialNumber: Yup.string().required("Serial Number is required"),
    initialCount: Yup.number().required("Initial Round Count is required"),
  });

  const formik = useFormik({
    initialValues: {
      firearm: "",
      make: "",
      model: "",
      calibre: "",
      serialNumber: "",
      initialCount: 0
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);

      addFirearm(values.firearm, values.make, values.model, values.calibre, values.serialNumber, Number(values.initialCount)).then((res: any)=>{

        setSubmitting(false);

        if(res)
        {
          formik.resetForm();
          setStatus("Firearm Added.");
        }
        else
        {
          setStatus("Add Error please try again.");
        }
      })
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 1 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
          <h1 style={{textAlign: "center"}}>Add Firearm</h1>
          <TextField
              margin="normal"
              required
              fullWidth
              id="firearm"
              label="Firearm"
              name="firearm"
              autoComplete="firearm"
              autoFocus
              value={formik.values.firearm}
              onChange={formik.handleChange}
              error={formik.touched.firearm && Boolean(formik.errors.firearm)}
              helperText={formik.touched.firearm && formik.errors.firearm}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="make"
              label="Make"
              name="make"
              autoComplete="make"
              autoFocus
              value={formik.values.make}
              onChange={formik.handleChange}
              error={formik.touched.make && Boolean(formik.errors.make)}
              helperText={formik.touched.make && formik.errors.make}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="model"
              label="Model"
              name="model"
              autoComplete="model"
              autoFocus
              value={formik.values.model}
              onChange={formik.handleChange}
              error={formik.touched.model && Boolean(formik.errors.model)}
              helperText={formik.touched.model && formik.errors.model}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="serialNumber"
              label="Serial Number"
              name="serialNumber"
              autoComplete="serialNumber"
              autoFocus
              value={formik.values.serialNumber}
              onChange={formik.handleChange}
              error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
              helperText={formik.touched.serialNumber && formik.errors.serialNumber}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="calibre"
              label="Calibre"
              name="calibre"
              autoComplete="calibre"
              autoFocus
              value={formik.values.calibre}
              onChange={formik.handleChange}
              error={formik.touched.calibre && Boolean(formik.errors.calibre)}
              helperText={formik.touched.calibre && formik.errors.calibre}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="initialCount"
              label="Initial Count"
              type="initialCount"
              id="initialCount"
              autoComplete="current-password"
              value={formik.values.initialCount}
              onChange={formik.handleChange}
              error={formik.touched.initialCount && Boolean(formik.errors.initialCount)}
              helperText={formik.touched.initialCount && formik.errors.initialCount}
            />
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Add
            </Button>
            {status?.includes('Error') && <Alert severity="error">Error: {status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            {submitting && <CircularProgress />}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AddFirearm;
