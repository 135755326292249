import { Box, Button, Container, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Form } from "formik";
import {FunctionComponent, useEffect} from "react";
import { useOrientation } from "../hooks/useOrientation";
import { useNavigate } from "react-router-dom";
import { authenticationService } from "../services/authentication-service";
import React from "react";

const Checkout: FunctionComponent = () => {
  interface State {
    message: string;
  }
  const [state, setState] = React.useState<State>({message:"No data received from IFrame, yet."});
  const navigate = useNavigate();
  const from = "/";

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    window.addEventListener(
      "message",
      (ev: MessageEvent<{ type: string; message: string }>) => {
        if (typeof ev.data !== "object") return;
        if (!ev.data.type) return;
        if (ev.data.type !== "button-click") return;
        if (!ev.data.message) return;
        var msg = ev.data.message;
        setState({
          message: ev.data.message
        });
      });

  }, [navigate, from]);  

  const openInNewTab = (url: string | URL | undefined) => {
    window.open(url, "_blank", "noreferrer");
  };

  function onClick()
  {
    //openInNewTab("https://api.langarrc.co.uk/payment/checkout.html")
    
    //openInNewTab(window.location.origin + "/payment/checkout.html")
    openInNewTab("http://localhost:3000/payment/checkout.html")
  }

  const theme = createTheme();
  const orientation = useOrientation();

  return (<ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box
        sx={{
          marginTop: orientation === "portrait" ? 1 : 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Box component="form" noValidate sx={{mt: 1}}>
        <h1 style={{textAlign: "center"}}>Payment Page...</h1>
        </Box>
        <div>
        <div>
          <h4>{state.message}</h4>
        </div>
        <iframe
          className="iframe"
          title="Payment IFrame"
          src={window.location.origin + "/payment/checkout.html"}
        />
      </div>
      </Box>
    </Container>
  </ThemeProvider>);
  
  /*return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 1 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box component="form" noValidate sx={{mt: 1}}>
          <h1 style={{textAlign: "center"}}>Payment Page...</h1>
          <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Pay
          </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );*/
};

export default Checkout;