import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function CompletePage(props: {text:string, subtext:string, buttonText:string, buttonFunction:any}) {

  function onClick()
  {
    props.buttonFunction();
  }

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            {props.text}
          </Typography>
          <Typography component="h1" variant="subtitle1">
            {props.subtext}
          </Typography>
          {props.buttonFunction != null && <Grid>
          <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              {props.buttonText}
            </Button>
          </Grid>}
          
        </Box>
  );
};

export default CompletePage;
