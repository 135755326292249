import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState, useContext} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Copyright from "../components/Copright";
import image1 from "../images/Logo.jpg";
import {useOrientation} from "../hooks/useOrientation";
import {isDesktop} from "react-device-detect";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, CircularProgress} from "@mui/material";
import {authenticationService, UserContext} from "../services/authentication-service";

const ChangePassword: FunctionComponent = () => {
  const currentUser = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }
  }, [navigate, from]);


  const validationSchema = Yup.object({
    password: Yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirmation Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);
      
      authenticationService.changePassword(currentUser?.username !== undefined ? currentUser.username : "", values.password).then(
        () => {
          setSubmitting(false);
          setStatus("Password Changed");
        },
        (error) => {
          setSubmitting(false);
          //setStatus(error instanceof Error ? error.message : error);
          setStatus("Unknown Error");
        },
        /*
        , (error) => {
        setSubmitting(false);

        if (!error) {
          setStatus("Password Changed");
        } else  {
          setStatus("Unknown Error");
        }*/
      );
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 4 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {(orientation === "portrait" || isDesktop) && (
            <img
              src={image1}
              style={{
                maxWidth: "100%",
                maxHeight: "10%",
              }}
              alt="Langar RC"
            />
          )}

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  id="passwordConfirmation"
                  autoComplete="confirm-password"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                  helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                />
              </Grid>

            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Reset Password
            </Button>
            {status?.includes('Error') && <Alert severity="error">Error: {status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            {submitting && <CircularProgress />}
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
    </ThemeProvider>
  );
};

export default ChangePassword;
