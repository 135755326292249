import React, {useState, FunctionComponent} from 'react';
import {getProgressives, deleteRangeLogs, getLogUserLookups} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import {authenticationService} from "../services/authentication-service";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import {useFormik} from "formik";
import * as Yup from "yup";

const FirearmLog: FunctionComponent<{fromAdmin?: boolean}> = (props) => {
  
  interface IUser {
      id: number;
      date: string;
      firearm: string;
      make: string;
      model: string;
      serialNumber: string;
      calibre: string;
      range: string;
      activity: string;
      count: string;
  };

  interface ILookup {
    id: number;
    description: string;
  };
  
  const [userLookups, setUserLookups] = useState<ILookup[]>();
  const [data, setData] = useState<IUser[]>();
  const [admin, setAdmin] = useState<Boolean>(false);
  
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    if(authenticationService.currentUserValue?.accessLevel && props.fromAdmin)
    {
      setAdmin(true);
      getLogUserLookups().then((data)=>setUserLookups(data as ILookup[]));
    }
    else
    {
      getProgressives().then((data)=>setData(data as IUser[]));
    }
  }, [navigate, from, props.fromAdmin]);

  const validationSchema = Yup.object({
    memberId: Yup.number().required("memberId is required"),
  });

  const formik = useFormik({
    initialValues: {
      memberId: -1
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
  });

  const [selectedRows, setSelectedRows] = useState<IUser[]>();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },

    
    { field: 'date', headerName: 'Date', width: 130 },
    { field: 'firearm', headerName: 'Firearm', width: 130 },
    { field: 'make', headerName: 'Make', width: 130 },
    { field: 'model', headerName: 'Model', width: 130 },
    { field: 'serialNumber', headerName: 'SerialNo', width: 130 },
    { field: 'calibre', headerName: 'Calibre', width: 130 },
    { field: 'range', headerName: 'Range', width: 130 },
    { field: 'activity', headerName: 'Activity', width: 130 },
    {
      field: 'count',
      headerName: 'Count',
      type: 'number',
      width: 90,
    },
  ];

  const handleOnClick = () => {
    var entriesToDelete: number[] = [];
    selectedRows?.map(
      (row)=>entriesToDelete.push(row.id));

    if(entriesToDelete.length)
    {
      deleteRangeLogs(entriesToDelete).then((data)=>setData(data as IUser[]));
    }
  }; 

  function getDataRows()
  {
    var rows: IUser[] = [];

    data?.map(
      (row) => (
        rows.push(row)
    ));
    
    return rows;
  };
  function showDeleteButton() {
    return !admin && <Button type="submit" startIcon={<DeleteIcon />} onClick={handleOnClick} fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
    Delete</Button>
  }

  function showUserLookup() {
    return userLookups?.length && (<div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Member"
                value={formik.values.memberId}
                onChange={
                  value=>{
                    formik.setFieldValue('memberId', value.target.value); 
                    setData(undefined);
                    getProgressives(value.target.value.toString()).then((data)=>setData(data as IUser[]));
                  }
                }
                error={formik.touched.memberId && Boolean(formik.errors.memberId)}
              >
                {
                  userLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.memberId ? <div className='error'>{formik.errors.memberId}</div> : null}
            </FormControl><br/><br/>
          </div>);
  }

  return (
    <div>
      <h1 style={{textAlign: "center"}}>Firearm Log</h1>
      {showUserLookup()}
      <div style={{ height: 650, width: '100%' }}>
      <strong>Last 12 Months Records</strong>
        <DataGrid
            rows={getDataRows()}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = data?.filter((row: { id: GridRowId; }) =>
                selectedIDs.has(row.id),
              );
    
              setSelectedRows(selectedRows);
            }}
          />
          {showDeleteButton()}
      </div>
    </div>
  );
};

export default FirearmLog;
