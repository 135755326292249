import {useContext, useEffect, FunctionComponent} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuListComposition from "./MenuList";
import {User, UserContext} from "../services/authentication-service";

const TopBar: FunctionComponent = () => {
  const currentUser = useContext(UserContext);

  useEffect(() => {
    console.dir(currentUser);
  }, [currentUser]);

  function renderSwitch(param: User | null) {
    if (param !== null) {
      switch (param.accessLevel) {
        case "-1":
          return (
            <Toolbar>
              <MenuListComposition name="Menu">Menu</MenuListComposition>
              <MenuListComposition name="Range">Range</MenuListComposition>
              <MenuListComposition name="Account">Account</MenuListComposition>
            </Toolbar>
          );
        case "1":
          return (
            <Toolbar>
              <MenuListComposition name="Menu">Menu</MenuListComposition>
              <MenuListComposition name="Range">Range</MenuListComposition>
              <MenuListComposition name="AdminMenu">Admin</MenuListComposition>
              <MenuListComposition name="Account">Account</MenuListComposition>
            </Toolbar>
          );
        case "2":
          return (
            <Toolbar>
              <MenuListComposition name="Menu">Menu</MenuListComposition>
              <MenuListComposition name="Range">Range</MenuListComposition>
              <MenuListComposition name="SuperAdminMenu">Admin</MenuListComposition>
              <MenuListComposition name="Account">Account</MenuListComposition>
            </Toolbar>
          );
        case "3":
          return (
            <Toolbar>
              <MenuListComposition name="Menu">Menu</MenuListComposition>
              <MenuListComposition name="Range">Range</MenuListComposition>
              <MenuListComposition name="SuperAdminMenu">Admin</MenuListComposition>
              <MenuListComposition name="DevMenu">Dev</MenuListComposition>
              <MenuListComposition name="Account">Account</MenuListComposition>
            </Toolbar>
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  }

  return (
    <AppBar color="primary" position="static">
      {renderSwitch(currentUser)}
    </AppBar>
  );
};

export default TopBar;
