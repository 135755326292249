import React, {FunctionComponent} from "react";

const RegisterConfirmEmail: FunctionComponent = () => {
  return (
    <div>
      <h1>Hi, thanks for registering!</h1>
      <p>Please check your email to complete registration.</p>
      <p>Email may take serval minutes, please also check spam mailbox</p>
    </div>
  );
};

export default RegisterConfirmEmail;
