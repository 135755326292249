import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState} from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useOrientation} from "../hooks/useOrientation";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, CircularProgress} from "@mui/material";

//import MobileDatePicker from "@mui/lab/MobileDatePicker";
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {getFirearmsLookups, addFirearmLog} from "../services/user-service";
import enLocale from 'date-fns/locale/en-GB';
import {authenticationService} from "../services/authentication-service";

const AddFirearmLog: FunctionComponent = () => {
  
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  const [firearm, setFirearm] = React.useState('');
  const [range, setRange] = React.useState(-10);
  const [activity, setActivity] = React.useState('');

  const [firearmLookups, setFirearmLookups] = useState<IFirearmLogLookups>();

  interface IFirearmLogLookups {
      firearms: ILookup[];
      ranges: ILookup[];
      rangeActivities: ILookup[];
  };

  interface ILookup {
    id: number;
    description: string;
  };
/*
  const handleFirearmChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setFirearm(event.target.value);
  };
  const handleRangeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setRange(event.target.value);
  };
  const handleActivityChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setActivity(event.target.value);
  };*/

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getFirearmsLookups().then((data)=>setFirearmLookups(data as IFirearmLogLookups));
  }, [navigate, from]);

  const validationSchema = Yup.object({
    firearm: Yup.string().required("Firearm is required"),
    range: Yup.string().required("Range is required"),
    activity: Yup.string().required("Activity is required"),
    roundCount: Yup.number().required("Round Count is required"),
  });

  const formik = useFormik({
    initialValues: {
      firearm: "",
      range: "",
      activity: "",
      customRange: "",
      roundCount: 0
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);
      
      addFirearmLog(new Intl.DateTimeFormat('en-GB').format(new Date(value as Date)), Number(firearm), Number(range), Number(activity), values.roundCount, values.customRange).then((res: any)=>{

        setSubmitting(false);

        if(res)
        {
          if(formik.values.customRange !== "" && formik.values.range === "-2")
            getFirearmsLookups().then((data)=>setFirearmLookups(data as IFirearmLogLookups));

          formik.resetForm();
          setRange(-10);
          setStatus("Firearm Log Added.");
        }
        else
        {
          setStatus("Add Error please try again.");
        }

      })
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 1 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
            <h1 style={{textAlign: "center"}}>Add Firearm Log</h1>
            <LocalizationProvider 
            dateAdapter={AdapterDateFns} 
            //locale={enLocale}
            adapterLocale={enLocale}
            >
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Date"
                  value={value}
                  onChange={(newValue) => {
                    newValue && setValue(newValue);
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Firearm</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Firearm"
                value={formik.values.firearm}
                onChange={
                  value=>{
                    formik.setFieldValue('firearm', value.target.value); 
                    setFirearm(value.target.value);
                  }
                }
                error={formik.touched.firearm && Boolean(formik.errors.firearm)}
              >
                {
                  firearmLookups?.firearms.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.firearm ? <div className='error'>{formik.errors.firearm}</div> : null}
            </FormControl>
            
              {range === -2 ? <div><TextField
              margin="normal"
              required
              fullWidth
              name="customRange"
              label="Other Range"
              type="customRange"
              id="customRange"
              //autoComplete="current-password"
              value={formik.values.customRange}
              onChange={formik.handleChange}
              //error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
              //helperText={formik.touched.roundCount && formik.errors.roundCount}
            /></div> : 
            <div><FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="demo-simple-select-standard-label">Range</InputLabel><Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Range"
                value={formik.values.range}
                onChange={
                  value=>{
                    formik.setFieldValue('range', value.target.value); 
                    setRange(Number(value.target.value));
                  }
                }
                error={formik.touched.range && Boolean(formik.errors.range)}
                
              >
                {
                  firearmLookups?.ranges.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select></FormControl></div>}
              
              {formik.errors.range ? <div className='error'>{formik.errors.range}</div> : null}
            
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300}}>
              <InputLabel id="demo-simple-select-standard-label">Activity</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Activity"
                value={formik.values.activity}
                onChange={
                  value=>{
                    formik.setFieldValue('activity', value.target.value); 
                    setActivity(value.target.value);
                  }
                }
                error={formik.touched.activity && Boolean(formik.errors.activity)}
              >
                {
                  firearmLookups?.rangeActivities.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.activity ? <div className='error'>{formik.errors.activity}</div> : null}
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              name="roundCount"
              label="Round Count"
              type="roundCount"
              id="roundCount"
              autoComplete="current-password"
              value={formik.values.roundCount}
              onChange={formik.handleChange}
              error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
              helperText={formik.touched.roundCount && formik.errors.roundCount}
            />
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Add
            </Button>
            {status?.includes('Error') && <Alert severity="error">Error: {status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            {submitting && <CircularProgress />}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AddFirearmLog;
