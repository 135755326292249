import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import RegisterPage from './RegisterPage';
//import DetailsPage from './DetailsPage';
import CompletePage from './CompletePage';
import Copyright from "../components/Copright";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useState} from 'react';

const theme = createTheme();

function Register(props: {page:string}) {
  const navigate = useNavigate();

  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  const [page, setPage] = useState<string>("");

  const handleOnClick = () => {
    navigate("/login");
  };

  React.useEffect(() => {
    setPage(props.page);
  }, [navigate, from, props]);

  function getPage()
  {
    switch(page)
    {
      case "register":
        return <RegisterPage /*memberId={null}*/ prePopulate={null} readonly={false} ignorePassword={false} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("complete")}}/>
      case "complete":
        return <CompletePage text={"Registration Complete"} subtext={"login to complete renewal"} buttonText={"Login"} buttonFunction={(event: any) => {handleOnClick()}}/>
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h5">
            Langar Rifle Club Applications
          </Typography>
        {getPage()}
      </Box>
      <Copyright sx={{mt: 5}} />
      </Container>
    </ThemeProvider>
  );
};

export default Register;
