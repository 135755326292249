import {authenticationService} from "../services/authentication-service";

export function authHeader(): Record<string, string> {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return {Authorization: `Bearer ${currentUser.token}`};
  } else {
    return {};
  }
}

export function authHeaderString(): string {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    return `Bearer ${currentUser.token}`;
  } else {
    return "";
  }
}
