import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import RegisterPage from './RegisterPage';
import {getPendingApplicantLookup, getRegisteredMember} from "../services/user-service";
import {authenticationService} from "../services/authentication-service";
import React, {useState} from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Copyright from "../components/Copright";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const theme = createTheme();

function ViewApplication(props:any) {
  interface ILookup {
    id: number;
    description: string;
  };
  const [userLookups, setUserLookups] = useState<ILookup[]>();
  const [memberId, setMemberId] = useState<string>("");
  const [registerData, setRegisterData] = useState<any>(null);
  const navigate = useNavigate();

  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    if(authenticationService.currentUserValue?.accessLevel)
    {
      getPendingApplicantLookup().then((data)=>setUserLookups(data as ILookup[]));
    }
  }, [navigate, from]);

  function getPages()
  {
      return <div>
        {registerData && <RegisterPage /*memberId={memberId}*/ prePopulate={registerData} readonly={true} ignorePassword={false} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {}}/>}
      </div>;
  }

  function showUserLookup() {
    return userLookups?.length && (<div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Member"
                value={memberId}
                onChange={
                  value=>{
                    setMemberId(value.target.value);
                    getRegisteredMember(Number(value.target.value)).then((rm_data:any)=>setRegisterData(rm_data));
                  }
                }
              >
                {
                  userLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
            </FormControl><br/><br/>
          </div>);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h5">
            Langar Rifle Club Applications
          </Typography>
          <br></br>
        {memberId === "" && (userLookups?.length ? showUserLookup() : "No Pending Applications")}
        {memberId !== "" && getPages()}
      </Box>
      <Copyright sx={{mt: 5}} />
      </Container>
    </ThemeProvider>
  );
};

export default ViewApplication;
