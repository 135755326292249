import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Layout } from './components/Layout';
import LoginPage  from './pages/LoginPage';
import PasswordReset  from './pages/PasswordReset';
import ChangePassword  from './pages/ChangePassword';
import Application  from './pages/Application';
import Renewal  from './pages/Renewal';
import ViewApplication  from './pages/ViewApplication';
import {RequireAuth} from './components/RequireAuth';
import RegisterConfirmEmail from './pages/RegisterCheckEmail';
import FirearmLog from './pages/FirearmLog';
import Firearms from './pages/Firearms';
import FirearmLogSummary from './pages/FirearmLogSummary';
import AddFirearmLog from './pages/AddFirearmLog';
import AddFirearm from './pages/AddFirearm';
import BookRange from './pages/BookRange';
import RangeBookings from './pages/RangeBookings';
import AllRangeBookings from './pages/AllRangeBookings';
import ContactList from './pages/ContactList';
import MemberRenewals from './pages/MemberRenewals';
import { ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import './App.css'
import Register from './pages/Register';
import ViewMember from './pages/ViewMember';
import Checkout from './pages/Checkout';
import AddEvent from './pages/AddEvent';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const baseUrl = document.querySelector('base')?.getAttribute('href') ?? undefined;

function App() {
  const adminProps = { // make sure all required component's inputs/Props keys&types match
    fromAdmin: true
  }

  return (
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
          <Layout>
            <Routes>
              <Route path="" element={<RequireAuth redirectTo={"/login"}><FirearmLogSummary /></RequireAuth>} />
              <Route path="/" element={<RequireAuth redirectTo={"/login"}><FirearmLogSummary /></RequireAuth>} />
              <Route path="/home" element={<RequireAuth redirectTo={"/login"}><FirearmLogSummary /></RequireAuth>} />
              
              <Route path="/login" element={<LoginPage />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/register-direct" element={<Register page={"register"}/>} />

              <Route path="/register" element={<Application page={"register"}/>} />
              <Route path="/register-confirm-email" element={<RegisterConfirmEmail />} />
              
              <Route path="/firearm-log"  element={<RequireAuth redirectTo={"/login"}><FirearmLog/></RequireAuth>} />
              <Route path="/firearms"  element={<RequireAuth redirectTo={"/login"}><Firearms/></RequireAuth>} />
              <Route path="/firearm-log-summary"  element={<RequireAuth redirectTo={"/login"}><FirearmLogSummary/></RequireAuth>} />
              <Route path="/add-firearm-log"  element={<RequireAuth redirectTo={"/login"}><AddFirearmLog/></RequireAuth>} />
              <Route path="/add-firearm"  element={<RequireAuth redirectTo={"/login"}><AddFirearm/></RequireAuth>} />
              
              <Route path="/book-range"  element={<RequireAuth redirectTo={"/login"}><BookRange/></RequireAuth>} />
              <Route path="/view-range-booking"  element={<RequireAuth redirectTo={"/login"}><RangeBookings/></RequireAuth>} />

              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/renewal"  element={<RequireAuth redirectTo={"/login"}><Renewal page={"register"}/></RequireAuth>} />

              <Route path="/admin-contact-list"  element={<RequireAuth redirectTo={"/login"}><ContactList/></RequireAuth>} />
              <Route path="/admin-firearm-log-summary"  element={<RequireAuth redirectTo={"/login"}><FirearmLogSummary {...adminProps}/></RequireAuth>} />
              <Route path="/admin-firearm-log"  element={<RequireAuth redirectTo={"/login"}><FirearmLog {...adminProps}/></RequireAuth>} />
              <Route path="/admin-member-renewals"  element={<RequireAuth redirectTo={"/login"}><MemberRenewals/></RequireAuth>} />
              <Route path="/admin-view-new-appliations"  element={<RequireAuth redirectTo={"/login"}><ViewApplication/></RequireAuth>} />
              <Route path="/admin-view-range-booking"  element={<RequireAuth redirectTo={"/login"}><AllRangeBookings/></RequireAuth>} />
              <Route path="/admin-view-member"  element={<RequireAuth redirectTo={"/login"}><ViewMember page={"register"}/></RequireAuth>} />

              <Route path="/payment"  element={<RequireAuth redirectTo={"/login"}><Checkout/></RequireAuth>} />
              <Route path="/add_event"  element={<RequireAuth redirectTo={"/login"}><AddEvent/></RequireAuth>} />
            </Routes>
          </Layout>
        </ThemeProvider >
      </BrowserRouter>
  );
}

export default App;
