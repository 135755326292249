import React, {useState, FunctionComponent} from 'react';
import {getAllRangeBookings, adminDeleteRangeBooking} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import {authenticationService} from "../services/authentication-service";

const RangeBookings: FunctionComponent = (props) => {
  
  interface IUser {
      id: number;
      date: string;
      firearm: string;
      make: string;
      model: string;
      serialNumber: string;
      calibre: string;
      range: string;
      activity: string;
      count: string;
  };

  const [data, setData] = useState<IUser[]>();
  
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getAllRangeBookings().then((data)=>setData(data as IUser[]));
  }, [navigate, from]);

  const [selectedRows, setSelectedRows] = useState<IUser[]>();
  //{"id":19909,"date":"2023-04-11T00:00:00","timeRange":"0730-0855","noShooters":1,"shooterList":"darren"}
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },

    
    { field: 'date', headerName: 'Date', width: 130 },
    { field: 'timeRange', headerName: 'Slot', width: 90 },
    { field: 'noShooters', headerName: 'Num Shooters', width: 110 },
    { field: 'shooterList', headerName: 'Shooters', width: 200 },
  ];

  const handleOnClick = () => {
    var entriesToDelete: number[] = [];
    selectedRows?.map(
      (row)=>entriesToDelete.push(row.id));

    if(entriesToDelete.length)
    {
      entriesToDelete.forEach(element => {
        adminDeleteRangeBooking(element).then((data)=>setData(data as IUser[]));
      });
    }
  }; 

  function getDataRows()
  {
    var rows: IUser[] = [];

    data?.map(
      (row) => (
        rows.push(row)
    ));
    
    return rows;
  };

  return (
    <div>
      <h1 style={{textAlign: "center"}}>Range Bookings</h1>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
            rows={getDataRows()}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = data?.filter((row: { id: GridRowId; }) =>
                selectedIDs.has(row.id),
              );
    
              setSelectedRows(selectedRows);
            }}
          />
          <Button type="submit" startIcon={<DeleteIcon />} onClick={handleOnClick} fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Cancel
          </Button>
      </div>
    </div>
  );
};

export default RangeBookings;
