import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Select} from "@mui/material";
//import {getTitleLookups} from "../services/user-service";
//import MobileDatePicker from "@mui/x-date-pickers/MobileDatePicker";
//import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getTitleLookups, postDataAuth } from "../services/user-service";
import { authenticationService } from "../services/authentication-service";
//import { getTitleLookups } from "../services/user-service";

function NRADetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack:any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  //const [value, setValue] = React.useState<Date | null>(new Date());
  const [status, setStatus] = useState<string | undefined>(undefined);

  const today = new Date();
  today.setHours(0, 0, 0, 0)

  const [initialValues, setinitialValues] = useState({
    number: "LRC_" + authenticationService.currentUserValue?.id.toString().padStart(4, '0'),
    membershipType: "",
    memberType: "",
    primaryClub: "Langar Rifle Club",
    primaryClubSecretary: "Maxine Woodward",
    primaryClubSecretaryContactNo: "07769228691",
    disabled: props.readonly,
  });

  function onClick()
  {
    props.onBack();
  }

  interface IMembershipDetails {
    id: number,
    membershipNumber: string,
    membershipTypeId: number,
    positionId: number,
    primaryClub: string,
    primaryClubSecretary: string,
    PrimaryClubContactNumber: string,
    expiryDate: Date
    JointMembers: string
  };
/*
  public class MembershipData
    {
        public int Id { get; set; }
        public string MembershipNumber { get; set; }
        public int MembershipTypeId { get; set; }
        public DateTime ExpiryDate { get; set; }
        public int PositionId { get; set; }
        public string PrimaryClub { get; set; }
        public string PrimaryClubSecretary { get; set; }
        public string PrimaryClubContactNumber { get; set; }
        public string JointMembers { get; set; }

    }*/

  const validationSchema = Yup.object({
    number: Yup.string().required("Membership Number is required").min(3, "Minimum 3 digits long"),
    memberType: Yup.string().required("Member Type is required"),
    membershipType: Yup.string().required("Membership Type is required"),
    declaration: Yup.boolean().oneOf([true],'Please Confirm the Declaration')
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      number: initialValues.number,
      membershipType: initialValues.membershipType,
      memberType: initialValues.memberType,
      primaryClub: initialValues.primaryClub,
      primaryClubSecretary: initialValues.primaryClubSecretary,
      primaryClubSecretaryContactNo: initialValues.primaryClubSecretaryContactNo,
      declaration: false,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {
        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: IMembershipDetails = {
            id: userId,
            membershipNumber: values.number,
            membershipTypeId: Number(values.membershipType),
            positionId: Number(values.memberType),
            primaryClub: values.primaryClub,
            primaryClubSecretary: values.primaryClubSecretary,
            PrimaryClubContactNumber: values.primaryClubSecretaryContactNo,
            expiryDate: new Date("12/31/" + today.getFullYear()),
            JointMembers : ""
        };

        postDataAuth("AddMembershipData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function setFormikValues(data: IMembershipDetails, title_data: ILookup[])
  {
    if(data)
    {
      //setValue(data.nraExpiryDate);
      setinitialValues({
        number: data.membershipNumber,
        membershipType: data.membershipTypeId.toString(),
        memberType: data.positionId.toString(),
        primaryClub: data.primaryClub,
        primaryClubSecretary: data.primaryClubSecretary,
        primaryClubSecretaryContactNo: data.PrimaryClubContactNumber,
        disabled: props.readonly,
      });
    }
  }

  const [memberTypeLookups, setMemberTypeLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  const [membershipTypeLookups, setMembershipTypeLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  React.useEffect(() => {
    getTitleLookups("GetPositionsLookups").then((data)=>{
      setMemberTypeLookups(data as ILookup[])

      getTitleLookups("GetMembershipTypesLookups").then((data)=>{
        setMembershipTypeLookups(data as ILookup[])

        if(props.prePopulate/* && props.memberId != null*/)
        {
          //props.prePopulate(props.memberId).then((rm_data:any)=>setFormikValues(rm_data as IRegisteredMember, data as ILookup[]));
          setFormikValues(props.prePopulate as IMembershipDetails, data as ILookup[])
        }
      });
    });
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            Membership Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="membershipNumber"
                  label="`Membership Number"
                  name="membershipNumber"
                  autoComplete="membershipNumber"
                  disabled={true}
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  error={formik.touched.number && Boolean(formik.errors.number)}
                  helperText={formik.touched.number && formik.errors.number}
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300}}>
              <InputLabel id="demo-simple-select-standard-label">Club Position</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Club Position"
                value={formik.values.memberType}
                //disabled={formik.values.disabled}
                onChange={
                  value=>{
                    formik.setFieldValue('memberType', value.target.value); 
                    //setActivity(value.target.value);
                  }
                }
                error={formik.touched.memberType && Boolean(formik.errors.memberType)}
              >
                {
                  memberTypeLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.memberType ? <div className='error'>{formik.errors.memberType}</div> : null}
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300}}>
              <InputLabel id="demo-simple-select-standard-label">Membership Type</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Membership Type"
                value={formik.values.membershipType}
                //disabled={formik.values.disabled}
                onChange={
                  value=>{
                    formik.setFieldValue('membershipType', value.target.value); 
                    //setActivity(value.target.value);
                  }
                }
                error={formik.touched.membershipType && Boolean(formik.errors.membershipType)}
              >
                {
                  membershipTypeLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.membershipType ? <div className='error'>{formik.errors.membershipType}</div> : null}
            </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="primaryClub"
                  label="Primary Club"
                  name="primaryClub"
                  autoComplete="membershipNumber"
                  value={formik.values.primaryClub}
                  onChange={formik.handleChange}
                  error={formik.touched.primaryClub && Boolean(formik.errors.primaryClub)}
                  helperText={formik.touched.primaryClub && formik.errors.primaryClub}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="primaryClubSecretary"
                  label="Primary Club Secretary"
                  name="primaryClubSecretary"
                  autoComplete="primaryClubSecretary"
                  value={formik.values.primaryClubSecretary}
                  onChange={formik.handleChange}
                  error={formik.touched.primaryClubSecretary && Boolean(formik.errors.primaryClubSecretary)}
                  helperText={formik.touched.primaryClubSecretary && formik.errors.primaryClubSecretary}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="primaryClubSecretaryContactNo"
                  label="Primary Club Secretary Contact Nos"
                  name="primaryClubSecretaryContactNo"
                  autoComplete="primaryClubSecretaryContactNo"
                  value={formik.values.primaryClubSecretaryContactNo}
                  onChange={formik.handleChange}
                  error={formik.touched.primaryClubSecretaryContactNo && Boolean(formik.errors.primaryClubSecretaryContactNo)}
                  helperText={formik.touched.primaryClubSecretaryContactNo && formik.errors.primaryClubSecretaryContactNo}
                />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns} 
              //locale={enLocale}
              adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Expiry Date"
                  value={new Date("12/31/" + today.getFullYear())}
                  onChange={(newValue) => {
                    //setValue(newValue);
                    //formik.setFieldValue('nraExpiryDate', newValue); 
                  }}
                  disabled={true}
                  onClose={() => {
                    //updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} 
                  />}
                />
              </Stack>
            </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Declaration</FormLabel>
                <FormGroup>
                  <FormControlLabel
                  control={
                    <Checkbox onChange={formik.handleChange} name="declaration" id="declaration"/>
                  }
                  label="I understand that as a member of Langar Rifle Club I will abide by the constitution and Byelaws. I confirm that I have not had an application for a firearm certificate or shotgun certificate refused or revoked and that I am not precluded from possessing a firearm or ammunition under section 21 of the Firearms Act 1968."
                  labelPlacement="top"
                />
              </FormGroup>
              <FormHelperText></FormHelperText>
              {formik.errors.declaration ? <div className='error'>{formik.errors.declaration}</div> : null}
              </FormControl>
              </Grid> 
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Renew
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Back
            </Button>
          </Box>
        </Box>
  );
};

export default NRADetailsPage;
