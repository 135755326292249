import * as React from "react";

import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

function Question(props: {question: string, onAnswer: any, onBack:any}) {

  function onClick(answer: boolean)
  {
    props.onAnswer(answer);
  }

  function onBackClick()
  {
    props.onBack();
  }

  const theme = createTheme();
  
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box sx={{mt: 1}}>
            <h1 style={{textAlign: "center"}}>{props.question}</h1>
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick(true)}} sx={{mt: 3, mb: 2}}>
              YES
            </Button>
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick(false)}} sx={{mt: 3, mb: 2}}>
              NO
            </Button>
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onBackClick()}} sx={{mt: 3, mb: 2}}>
              Back
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Question;
