import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
//import {getTitleLookups} from "../services/user-service";
//import MobileDatePicker from "@mui/x-date-pickers/MobileDatePicker";
//import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { postDataAuth } from "../services/user-service";
import { authenticationService } from "../services/authentication-service";
//import { getTitleLookups } from "../services/user-service";

function DetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack:any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [initialValues, setinitialValues] = useState({
    dateOfBirth: new Date(),
    homePhone: "",
    mobilePhone: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    county: "",
    postcode: "",
    country: "",
    //addressConfirmation: false,
    disabled: props.readonly,
  });

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }
  /*
  interface ILookup {
    id: number;
    description: string;
  };*/

  interface IMemberDetails {
    id: number;
    dateOfBirth: Date,
    homePhone: string,
    mobilePhone: string,
    addressLine1: string,
    addressLine2: string,
    town: string,
    county: string,
    postcode: string,
    country: string,
    //addressConfirmation: boolean
  };

  const validationSchema = Yup.object({
    //dob: Yup.string().required("Title is required"),
    homePhone: Yup.string().required("Title is required"),
    mobilePhone: Yup.string().required("Title is required"),
    addressLine1: Yup.string().required("Title is required"),
    town: Yup.string().required("Title is required"),
    county: Yup.string().required("Title is required"),
    postcode: Yup.string().required("Title is required"),
    country: Yup.string().required("Title is required")
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      dateOfBirth: initialValues.dateOfBirth,
      homePhone: initialValues.homePhone,
      mobilePhone: initialValues.mobilePhone,
      addressLine1: initialValues.addressLine1,
      addressLine2: initialValues.addressLine2,
      town: initialValues.town,
      county: initialValues.county,
      postcode: initialValues.postcode,
      country: initialValues.country,
      //addressConfirmation: initialValues.addressConfirmation,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {
        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: IMemberDetails = {
            id: userId,
            dateOfBirth: value ? value : new Date(),
            homePhone: values.homePhone,
            mobilePhone: values.mobilePhone,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            town: values.town,
            county: values.county,
            postcode: values.postcode,
            country: values.country,
        };

        postDataAuth("AddMemberData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function setFormikValues(data: IMemberDetails)
  {

    if(data)
    {
      setValue(data.dateOfBirth);
      setinitialValues({
        dateOfBirth: data.dateOfBirth,
        homePhone: data.homePhone,
        mobilePhone: data.mobilePhone,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        town: data.town,
        county: data.county,
        postcode: data.postcode,
        country: data.country,
        disabled: props.readonly,
      });
    }
  }

  React.useEffect(() => {
    //if (!authenticationService.currentUserValue) {
    //  navigate(from, {replace: true});
    //}
    
    //getTitleLookups().then((data)=>{
      if(props.prePopulate)
      {
        setFormikValues(props.prePopulate as IMemberDetails)
      }
    //});
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            Contact Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns} 
              //locale={enLocale}
              adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Date Of Birth"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  onClose={() => {
                    //updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            </Grid>  
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="homePhone"
                  label="Home Phone No"
                  name="homePhone"
                  autoComplete="homePhone"
                  disabled={formik.values.disabled}
                  value={formik.values.homePhone}
                  onChange={formik.handleChange}
                  error={formik.touched.homePhone && Boolean(formik.errors.homePhone)}
                  helperText={formik.touched.homePhone && formik.errors.homePhone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="mobilePhone"
                  label="Mobile Phone No"
                  name="mobilePhone"
                  autoComplete="mobilePhone"
                  disabled={formik.values.disabled}
                  value={formik.values.mobilePhone}
                  onChange={formik.handleChange}
                  error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
                  helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="addressLine1"
                  label="Address Line 1"
                  name="addressLine1"
                  autoComplete="addressLine1"
                  disabled={formik.values.disabled}
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                  helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="addressLine2"
                  label="Address Line 2"
                  name="addressLine2"
                  autoComplete="addressLine2"
                  disabled={formik.values.disabled}
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                  helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="town"
                  label="Town"
                  name="town"
                  autoComplete="town"
                  disabled={formik.values.disabled}
                  value={formik.values.town}
                  onChange={formik.handleChange}
                  error={formik.touched.town && Boolean(formik.errors.town)}
                  helperText={formik.touched.town && formik.errors.town}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="county"
                  label="County"
                  name="county"
                  autoComplete="county"
                  disabled={formik.values.disabled}
                  value={formik.values.county}
                  onChange={formik.handleChange}
                  error={formik.touched.county && Boolean(formik.errors.county)}
                  helperText={formik.touched.county && formik.errors.county}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="postcode"
                  label="Postcode"
                  name="postcode"
                  autoComplete="postcode"
                  disabled={formik.values.disabled}
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                  helperText={formik.touched.postcode && formik.errors.postcode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  autoComplete="country"
                  disabled={formik.values.disabled}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>{/*}
              <Grid item xs={12}>
              <FormControlLabel
                value="addressConfirmation"
                control={<Checkbox />}
                label="I have lived at this address for 2 or more years."
                labelPlacement="start"
              />
                </Grid>*/}
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default DetailsPage;
