import React, {FunctionComponent} from 'react';
import {getUserRenewalList} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";

import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {authenticationService} from "../services/authentication-service";

import Box from '@mui/material/Box';
/*import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";*/

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
//import { green, orange } from '@mui/material/colors';

//import WarningIcon from '@mui/icons-material/Warning';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <div>
        {/*<GridToolbarFilterButton />
        <GridToolbarDensitySelector />*/}
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}
/*
const steps = ['Details', 'FAC', 'CoC', 'NRA', 'Renewed'];

 function HorizontalStepperWithError(props: GridRenderCellParams<number>) {
  const {value} = props;

  const getLabel = (index: number, value: any, labelProps: any, label: {} | null | undefined) => 
  {
    if(isStepExpired(index, value))
    {
      //return <StyledStepLabel {...labelProps}>{label}</StyledStepLabel>
      return <StepLabel icon={
        //<Avatar className={classes.avatar}>
          <WarningIcon className={classes.WarningAmberIcon} fontSize="medium" />
        //</Avatar>
      } {...labelProps}>{label}</StepLabel>
    }
    else
      return <StepLabel {...labelProps}>{label}</StepLabel>
  };
  
  const isStepFailed = (step: number, value:any) => {
    return value !== null ? value[step] === 0 : false;
  };

  const isStepExpired = (step: number, value:any) => {
    return value !== null ? value[step] === 2 : false;
  };

  const isStepOptional = (step: number) => {
    return (step === 1 || step === 2 || step === 3);
  };

  const getActiveStep = (value:any) => {
    var retVal = 0;
    var index = 0;

    value.array.forEach((val: number) => {
      if(val === 1 || val === 2)
        retVal = index;

      index++;
    });

    return retVal;
  };

  const isStepSkipped = (step: number, value:any) => {
    var retVal = false;
    var index = 0;

    value.array.forEach((val: number) => {
      if(index > step && val === 1 && value[step] === 3)
        retVal = true;

        index++;
    });

    return retVal;
  };

  const useStyles = makeStyles(theme => ({
    avatar: {
      width: "1.2em",
      height: "1.2em",
      backgroundColor: green.A700
    },
    WarningAmberIcon: {
      //backgroundColor: orange.A700,
      color: orange.A700
    }
  }));

  const classes = useStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={getActiveStep(value)}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
            StepIconComponent?: React.ReactElement;
          } = {};

          if (isStepFailed(index, value)) {
            labelProps.optional = (
              <Typography variant="caption" color="error"></Typography>
            );
            labelProps.error = true;
          }

          if (isStepExpired(index, value)) {
            labelProps.optional = (
              <Typography variant="caption" color="error"></Typography>
            );
            labelProps.error = true;
          }

          //stepProps.completed = true;

          if (isStepSkipped(index, value) && isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption"></Typography>
            );

            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              {getLabel(index, value, labelProps, label)}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}*/

const MemberRenewals: FunctionComponent = (props) => {

  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getUserRenewalList(new Date().getFullYear().toString(), "complete").then(
      (data)=>
      {
        setRows(data);
        setUnfilteredRows(data);
      });

      getUserRenewalList(new Date().getFullYear().toString(), "incomplete").then(
        (data)=>
        {
          setExpiredRows(data);
          setUnfilteredRows(data);
        });

  }, [navigate, from]);
/*
  function renderCell(params: any) {
    return <HorizontalStepperWithError {...params} />;
  }*/

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },
    { field: 'forename', headerName: 'Forename', width: 100 },
    { field: 'surname', headerName: 'Surname', width: 100 },
    { field: 'renewalType', headerName: 'Renewal Type', width: 200 },
    //{ field: 'accountStatus', headerName: 'Account Status', width: 140 },
    //{ field: 'process', headerName: 'Renewal Process', renderCell: renderCell, width: 600},
  ];

  const expiredColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },
    { field: 'forename', headerName: 'Forename', width: 100 },
    { field: 'surname', headerName: 'Surname', width: 100 },
    { field: 'renewalType', headerName: 'Renewal Type', width: 200 },
    //{ field: 'accountStatus', headerName: 'Account Status', width: 140 },
    //{ field: 'process', headerName: 'Renewal Process', renderCell: renderCell, width: 600},
  ];

  const [rows, setRows] = React.useState<any[]>([]);
  const [expiredRows, setExpiredRows] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [unfilteredRows, setUnfilteredRows] = React.useState<any[]>([]);
  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = unfilteredRows?.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows??[]);
  };

  return (
    <div>
      <div>
        <h1 style={{textAlign: "center"}}>Member Renewals</h1>
        <div style={{ height: 650, width: '100%' }}>
          <DataGrid
              components={{ Toolbar: QuickSearchToolbar }}
              rows={rows}
              columns={columns}
              pageSize={8}
              rowsPerPageOptions={[8]}

              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
              }}
            />
        </div>
      </div>
      <div>
        <h1 style={{textAlign: "center"}}>Expired Member Renewals</h1>
        <div style={{ height: 650, width: '100%' }}>
          <DataGrid
              components={{ Toolbar: QuickSearchToolbar }}
              rows={expiredRows}
              columns={expiredColumns}
              pageSize={8}
              rowsPerPageOptions={[8]}

              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                },
              }}
            />
        </div>
      </div>
    </div>
  );
};

export default MemberRenewals;
