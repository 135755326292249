import {useState} from "react";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';

function RangeUsers(props: { id: number; currentBookings: any; slotTime: any; maxBookings: any; shooterList: any; onChange: any}) {

  const [totalCount, setTotalCount] = useState(props.currentBookings)
  const [currentCount] = useState(props.currentBookings)
  const [maxCount] = useState(props.maxBookings)

  function  firstPersonClick()
  {
    var count = 1;

    if(totalCount === 1)
    {
      count = 0;
    }

    onPersonClick(count)
  }

  function onPersonClick(count: number)
  {
    setTotalCount(count);
    props.onChange(props.id, count-currentCount);
  }
  

  const styles={
    personUsedStyle:{
    color: 'black'
    },

    personSelectedStyle:{
      color: 'white'
    },

    personNotSelectedStyle:{
      color: 'black'
    }
  }

  const getAnimalsContent = () => {
    let content = [];
    
    for (let i = 1; i <= maxCount; i++) 
    {
      if(i < currentCount)
      {
        content.push(<IconButton disabled><PersonIcon style={styles.personUsedStyle}/></IconButton>);
      }
      else if(i === currentCount)
      {
        content.push(<IconButton><PersonIcon style={styles.personUsedStyle} onClick={() => onPersonClick(i)}/></IconButton>);
      }
      else if(i <= totalCount)
      {
        if(i === 1)
        {
          content.push(<IconButton><PersonIcon style={styles.personSelectedStyle} onClick={firstPersonClick}/></IconButton>);
        }
        else
        {
          content.push(<IconButton><PersonIcon style={styles.personSelectedStyle} onClick={() => onPersonClick(i)}/></IconButton>);
        }
      }
      else
      {
        if(i === 1)
        {
          content.push(<IconButton><PersonOutlineIcon style={styles.personNotSelectedStyle} onClick={firstPersonClick}/></IconButton>);
        }
        else
        {
          content.push(<IconButton><PersonOutlineIcon style={styles.personNotSelectedStyle} onClick={() => onPersonClick(i)}/></IconButton>);
        }
      }
    }

    return content;
  };

  const getBoxColour = () => {
    var perc = currentCount / maxCount;

    if(perc >= 1)
      return '#FF6961';//return 'red';
    else if(perc > 0)
      return '#FFB54C';//return 'orange';
    else
      return '#8CD47E';//return 'green';
  };

  const getBoarderColour = () => {
    if(totalCount-currentCount > 0)
      return '4px dashed blue';
    else
      return '0px dashed grey';
  };

  const getBookingLabel = () => {

    if(currentCount === maxCount)
      return "Fully Booked";
    else
      return "Booking: " + (totalCount-currentCount)
  };

  const getBookingKey = () => {
    return(
      <div>
        <Box sx={{ p: 0.5, backgroundColor: '#96999e' }}>
          <PersonIcon style={styles.personUsedStyle}/>&nbsp;&nbsp;<label>Booked</label><br/>
          <PersonOutlineIcon style={styles.personNotSelectedStyle}/>&nbsp;&nbsp;<label>Available</label><br/>
          <PersonIcon style={styles.personSelectedStyle}/>&nbsp;&nbsp;<label>Selected</label><br/>
        </Box>
        <br/>
      </div>
    );
  };

  return (
    <div>
      {props.id === 1 ? getBookingKey() : null}
      <Box
        sx={{ p: 0.5, border: getBoarderColour(), backgroundColor: getBoxColour() }}
      >
      <label>{props.slotTime}</label>
      <br/>
      {getAnimalsContent()}
      &nbsp;&nbsp;&nbsp;<label>{totalCount + "/" + maxCount}</label>&nbsp;&nbsp;&nbsp;<label>{getBookingLabel()}</label>
      <br/>
      <label>{props.shooterList}</label>
    </Box>
  </div>
  );
};

export default RangeUsers;
