import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress} from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {getTitleLookups, postDataNoAuth} from "../services/user-service";
import { authenticationService } from "../services/authentication-service";

function RegisterPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, ignorePassword:boolean, onSubmit: any, onSuccess: any}) {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | undefined>(undefined);

  const [initialValues, setinitialValues] = useState({
    title: "",
    forename: "",
    surname: "",
    initials: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    disabled: props.readonly,
  });
  
  const [lookups, setLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  interface IRegisteredMember {
    id: number;
    titleId: number;
    forename: string;
    surname: string;
    initials: string;
    email: string;
    password: string;
  };

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    forename: Yup.string().required("Forename is required"),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    password: Yup.string().min(6, "Password should be of minimum 8 characters length").required("Password is required"),
    passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      title: initialValues.title,
      forename: initialValues.forename,
      surname: initialValues.surname,
      initials: initialValues.initials,
      email: initialValues.email,
      password: initialValues.password,
      passwordConfirmation: initialValues.passwordConfirmation,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {
        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: IRegisteredMember = {
            id: userId,
            titleId: Number(values.title),
            forename: values.forename,
            surname: values.surname,
            initials: values.initials,
            email: values.email,
            password: values.password
        };

        postDataNoAuth("AddRegisteredMember", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function setFormikValues(data: IRegisteredMember, title_data: ILookup[])
  {
    if(data)
    {
      setinitialValues({
        title: data.titleId.toString(),//title_data ? title_data[data.titleId].description : "",
        forename: data.forename,
        surname: data.surname,
        initials: data.initials,
        email: data.email,
        password: "**********",
        passwordConfirmation: "**********",
        disabled: props.readonly,
      });
    }
  }

  React.useEffect(() => {
    //if (!authenticationService.currentUserValue) {
    //  navigate(from, {replace: true});
    //}
    getTitleLookups("GetTitleLookups").then((data)=>{
      setLookups(data as ILookup[])

      if(props.prePopulate/* && props.memberId != null*/)
      {
        //props.prePopulate(props.memberId).then((rm_data:any)=>setFormikValues(rm_data as IRegisteredMember, data as ILookup[]));
        setFormikValues(props.prePopulate as IRegisteredMember, data as ILookup[])
      }
    });
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            Personal Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300}}>
              <InputLabel id="demo-simple-select-standard-label">Title</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Title"
                value={formik.values.title}
                disabled={formik.values.disabled}
                onChange={
                  value=>{
                    formik.setFieldValue('title', value.target.value); 
                    //setActivity(value.target.value);
                  }
                }
                error={formik.touched.title && Boolean(formik.errors.title)}
              >
                {
                  lookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.title ? <div className='error'>{formik.errors.title}</div> : null}
            </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="forename"
                  label="Forename"
                  name="forename"
                  autoComplete="forename"
                  disabled={formik.values.disabled}
                  value={formik.values.forename}
                  onChange={formik.handleChange}
                  error={formik.touched.forename && Boolean(formik.errors.forename)}
                  helperText={formik.touched.forename && formik.errors.forename}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="surname"
                  label="Surname"
                  name="surname"
                  autoComplete="surname"
                  disabled={formik.values.disabled}
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  error={formik.touched.surname && Boolean(formik.errors.surname)}
                  helperText={formik.touched.surname && formik.errors.surname}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="initials"
                  label="Middle Initials"
                  name="initials"
                  autoComplete="initials"
                  disabled={formik.values.disabled}
                  value={formik.values.initials}
                  onChange={formik.handleChange}
                  error={formik.touched.initials && Boolean(formik.errors.initials)}
                  helperText={formik.touched.initials && formik.errors.initials}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  disabled={formik.values.disabled}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  disabled={formik.values.disabled || props.ignorePassword}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  id="passwordConfirmation"
                  autoComplete="confirm-password"
                  disabled={formik.values.disabled || props.ignorePassword}
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                  helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                />
              </Grid>
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default RegisterPage;
