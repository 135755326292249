import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from "@mui/material";
//import {getTitleLookups} from "../services/user-service";
//import MobileDatePicker from "@mui/x-date-pickers/MobileDatePicker";
//import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getTitleLookups, postDataAuth } from "../services/user-service";
import { authenticationService } from "../services/authentication-service";
//import { getTitleLookups } from "../services/user-service";

function COCDetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack:any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [cbValue, setCBValue] = React.useState<IKVP[]>();
  const [status, setStatus] = useState<string | undefined>(undefined);


  const today = new Date();
  today.setHours(0, 0, 0, 0)

  const [initialValues, setinitialValues] = useState({
    cocNumber: "",
    cocExpiryDate: new Date(today.getDate()-1),
    disabled: props.readonly,
  });

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }

  interface ICOCDetails {
    id: number,
    expiryDate: Date,
    membershipNumber: string,
    CertifyingChairmanName: string,
    certificationList: IKVP[] | undefined,
  };

  interface IKVP {
    key: string,
    value: string,
  };

  const validationSchema = Yup.object({
    cocNumber: Yup.string().required("Membership Number is required").min(3, "Minimum 3 digits long"),
    cocExpiryDate: Yup.date()
    .min(today, "Date must be in the future")
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      cocNumber: initialValues.cocNumber,
      cocExpiryDate: initialValues.cocExpiryDate,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {

        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: ICOCDetails = {
            id: userId,
            expiryDate: values.cocExpiryDate,
            membershipNumber: values.cocNumber,
            certificationList: cbValue?.slice(),
            CertifyingChairmanName: ""
        };

        //req.certificationList = new Map<number, boolean>(cbValue);

        postDataAuth("AddMemberCOCData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function getMapValue(findKey: string)
  {
    var retVal = false;
    cbValue?.forEach(x => {
        if(x.key === findKey)
        {
          retVal = (x.value === 'True' || x.value === 'true')
        }
      })
    /*cbValue && Object.entries(cbValue).map( ([key, value]) => 
    {
        if(key === findKey)
          retVal = value;
    });*/
    
    return retVal;
  }

  function setDefaultList(title_data: ILookup[])
  {
    const newState = title_data?.map(obj => {
        return {key:obj.id.toString(), value: "false"};
    });

    newState && setCBValue(newState as IKVP[]);
  }

  function setFormikValues(data: ICOCDetails, title_data: ILookup[])
  {
    if(data)
    {
      setValue(data.expiryDate);
      data.certificationList?.length ? setCBValue(data.certificationList) : setDefaultList(title_data);
      setinitialValues({
        cocNumber: data.membershipNumber,
        cocExpiryDate: data.expiryDate,
        disabled: props.readonly,
      });
    }
  }

  const [lookups, setLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  React.useEffect(() => {
    getTitleLookups("GetShooterCertificationTypesLookups").then((data)=>{
      setLookups(data as ILookup[])

      if(props.prePopulate/* && props.memberId != null*/)
      {
        //props.prePopulate(props.memberId).then((rm_data:any)=>setFormikValues(rm_data as IRegisteredMember, data as ILookup[]));
        setFormikValues(props.prePopulate as ICOCDetails, data as ILookup[])
      }
    });
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);
/*
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });*/

  const updateState = (key:string, val:boolean) => {
    const newState = cbValue?.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.key === key) {
        return {...obj, value: val.toString()};
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setCBValue(newState);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setState({ ...state, [event.target.name]: event.target.checked });
    updateState(event.target.name, event.target.checked)
  };

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            Shooter Certification Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="cocNumber"
                  label="`Number"
                  name="cocNumber"
                  autoComplete="cocNumber"
                  disabled={formik.values.disabled}
                  value={formik.values.cocNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.cocNumber && Boolean(formik.errors.cocNumber)}
                  helperText={formik.touched.cocNumber && formik.errors.cocNumber}
                />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns} 
              //locale={enLocale}
              adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Expiry Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    formik.setFieldValue('cocExpiryDate', newValue); 
                  }}
                  onClose={() => {
                    //updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} 
                  error={formik.touched.cocExpiryDate && Boolean(formik.errors.cocExpiryDate)}
                  helperText={formik.touched.cocExpiryDate && formik.errors.cocExpiryDate}/>}
                />
              </Stack>
            </LocalizationProvider>
            </Grid>  
              <Grid item xs={12}>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">Select Qualifiations</FormLabel>
                <FormGroup>
              {
                lookups?.length && lookups.map((row) => (
                  <FormControlLabel
                  control={
                    <Checkbox checked={getMapValue(row.id.toString())} onChange={handleChange} name={row.id.toString()} />
                  }
                  label={row.description}
                />
                ))
              }
              </FormGroup>
              <FormHelperText></FormHelperText>
              </FormControl>
              </Grid>
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default COCDetailsPage;
