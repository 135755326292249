import React, {useState, FunctionComponent} from 'react';
import {getFirearms, deleteFirearms, updateFirearm} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridRowId} from '@mui/x-data-grid';
import {authenticationService} from "../services/authentication-service";

const Firearms: FunctionComponent = (props) => {
  
  interface IUser {
      id: number;
      firearm: string;
      make: string;
      model: string;
      serialNumber: string;
      calibre: string;
      initialCount: string;
  };

  const [data, setData] = useState<IUser[]>();
  
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getFirearms().then((data)=>setData(data as IUser[]));
  }, [navigate, from]);

  const [selectedRows, setSelectedRows] = useState<IUser[]>();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: true },

    
    { field: 'firearm', headerName: 'Firearm', width: 130, editable: true },
    { field: 'make', headerName: 'Make', width: 130, editable: true },
    { field: 'model', headerName: 'Model', width: 130, editable: true },
    { field: 'serialNumber', headerName: 'SerialNo', width: 130 },
    { field: 'calibre', headerName: 'Calibre', width: 130 },
  ];

  const handleOnClick = () => {
    var entriesToDelete: number[] = [];
    selectedRows?.map(
      (row)=>entriesToDelete.push(row.id));

    if(entriesToDelete.length)
    {
      deleteFirearms(entriesToDelete).then((data)=>setData(data as IUser[]));
    }
  }; 

  function getDataRows()
  {
    var rows: IUser[] = [];

    data?.map(
      (row) => (
        rows.push(row)
    ));
    
    return rows;
  };

  const validateAndUpdateFirearm = (Id: any, field: string, value: any, x: IUser) => {
    if(x.id === Id
      && ((field === "firearm" && x.firearm !== value)
      || (field === "make" && x.make !== value)
      || (field === "model" && x.model !== value))
      )
    {
      updateFirearm(Id, field, value).then((data)=>setData(data as IUser[]));
    }
  }

  const handleCellEditCommit = (params:any) => {

    var Id = params.id;
    var field = params.field;
    var value = params.value;

    data?.map(
      (x) => validateAndUpdateFirearm(Id, field, value, x));
  };


  return (
    <div>
      <h1 style={{textAlign: "center"}}>Firearms</h1>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
            rows={getDataRows()}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = data?.filter((row: { id: GridRowId; }) =>
                selectedIDs.has(row.id),
              );
    
              setSelectedRows(selectedRows);
            }}
            onCellEditCommit={handleCellEditCommit}
          />
          <Button type="submit" startIcon={<DeleteIcon />} onClick={handleOnClick} fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Delete
          </Button>
      </div>
    </div>
  );
};

export default Firearms;
