import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, FunctionComponent, useState } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../components/Copright";
import image1 from "../images/Logo.jpg";
import { useOrientation } from "../hooks/useOrientation";
import { isDesktop } from "react-device-detect";
import { authenticationService } from "../services/authentication-service";
import {/*useLocation,*/ useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginPage: FunctionComponent = () => {
  //const {user, login} = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      navigate(from, { replace: true });
    }
  });

  const validationSchema = Yup.object({
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    password: Yup.string().min(6, "Password should be of minimum 8 characters length").required("Password is required"),
  });

  const handleOnClick = () => {
    navigate("/password-reset");
  };

  const handleRegisterOnClick = () => {
    navigate("/register-direct");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);
      authenticationService.login(values.email, values.password).then(
        (user) => {
          navigate(from, { replace: true });
        },
        (error) => {
          setSubmitting(false);
          setStatus(error instanceof Error ? error.message : error + ". Have you renewed online?");
        },
      );
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 4 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {(orientation === "portrait" || isDesktop) && (
            <img
              src={image1}
              style={{
                maxWidth: "100%",
                maxHeight: "10%",
              }}
              alt="Langar RC"
            />
          )}

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={values.showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
            {/*<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />*/}

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
            {status && <Alert severity="error">Error: {status}</Alert>}
            {submitting && <CircularProgress />}
            <Grid container>
              <Grid item xs>
                <Link href="#" onClick={() => handleOnClick()} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" onClick={() => handleRegisterOnClick()} variant="body2">
                  Register
                </Link>
                {/*<Link href="#" onClick={() => window.open("https://langarrc.co.uk/renewalbeta.aspx", "_blank")} variant="body2">
                  Renew Online
            </Link>*/}
              </Grid>
              {/*
              <Grid item xs>
                <Link href="/password-reset" variant="body2">
                  Forgot password?
                </Link>
              </Grid>*/}
              {/*
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Renew Online"}
                </Link>
              </Grid>
              
              */}
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
