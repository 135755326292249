import {authHeader, authHeaderString} from "../helpers/auth-header";
import {handleResponse, handleResponseString} from "../helpers/handle-response";
//import {boolean, z} from "zod";

const apiRoot = process.env.REACT_APP_API_BASE_URL;
export const userService = {
  getAll,
  getProgressives,
  deleteRangeLogs,
  getContactList,
  getUserLookups,
  getLogUserLookups,
  getUserRenewalList,
  getFirearmsLogSummary,
  getFirearmsLogAnnualSummary,
  getFirearmsLookups,
  addFirearmLog,
  getRangeBookingSlots,
  bookRange,

  //Member
  getTitleLookups,
  getRegisteredMember,
  getPendingApplicantLookup
};

export interface responseSchema {
  status: string;
  desription: string;
}

function getAll() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return fetch(`Home/Authenticate`, requestOptions).then(handleResponse);
}

export async function getProgressives(userIdOverride?:string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetFirearmsLog${userIdOverride !== undefined ? '?userIdOverride='+userIdOverride :''}`, requestOptions).then(handleResponse);
}

export async function getRangeBookings() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetRangeBookings`, requestOptions).then(handleResponse);
}

export async function getFirearms() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetFirearms`, requestOptions).then(handleResponse);
}

export async function deleteRangeLogs(idList: any) {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
    body: JSON.stringify({idList}),
};
  return await fetch(`${apiRoot}RangeLog/DeleteFirearmLog`, requestOptions).then(handleResponse);
}

export async function deleteRangeBooking(id: number) {
  const requestOptions = {
    method: "GET",
    headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
    //body: JSON.stringify({idList}),
};
  return await fetch(`${apiRoot}RangeLog/deleteRangeBooking?Id=${id}`, requestOptions).then(handleResponse);
}

export async function deleteFirearms(idList: any) {
  const requestOptions = {
    method: "POST",
    headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
    body: JSON.stringify({idList}),
};
  return await fetch(`${apiRoot}RangeLog/DeleteFirearms`, requestOptions).then(handleResponse);
}

export async function getFirearmsLogSummary(userIdOverride?:string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetFirearmsLogSummary/all${userIdOverride !== undefined ? '?userIdOverride='+userIdOverride :''}`, requestOptions).then(handleResponse);
}

export async function getFirearmsLogAnnualSummary(userIdOverride?:string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetFirearmsLogSummary/last12${userIdOverride !== undefined ? '?userIdOverride='+userIdOverride :''}`, requestOptions).then(handleResponse);
}

export async function getFirearmsLookups() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}RangeLog/GetFirearmLookups`, requestOptions).then(handleResponse);
}

export async function getLookups(type:string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetLookups?type=${type}`, requestOptions).then(handleResponse);
}

export async function getRangeBookingSlots(Date: string) {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: JSON.stringify({Date}),
  };
  return await fetch(`${apiRoot}RangeLog/GetRangeBookingSlots`, requestOptions).then(handleResponse);
}

export function addFirearmLog(Date: string,  FirearmId: number, RangeId: number, RangeActivityId: number, RoundsFired: number, NewRange: string): Promise<boolean> {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: JSON.stringify({Date, FirearmId, RangeId, RangeActivityId, RoundsFired, NewRange}),
  };

  return fetch(`${apiRoot}RangeLog/AddFirearmLog`, requestOptions)
      .then(handleResponse)
      .then((resp) => {
        var retVal = false;

          if(resp.status !== "FAILED")
            retVal = true;

          return retVal;
      });
}

export function addFirearm(Firearm: string,  Make: string, Model: string, Calibre: string, SerialNumber: string, InitialCount: number): Promise<boolean> {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: JSON.stringify({Firearm, Make, Model, Calibre, SerialNumber, InitialCount}),
  };

  return fetch(`${apiRoot}RangeLog/AddFirearm`, requestOptions)
      .then(handleResponse)
      .then((resp) => {
        var retVal = false;

          if(resp.status !== "FAILED")
            retVal = true;

          return retVal;
      });
}

export async function updateFirearm(id:number, field:string, value:string) {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: JSON.stringify({id, field, value}),
  };

  return await fetch(`${apiRoot}RangeLog/UpdateFirearm`, requestOptions).then(handleResponse);
}

export function bookRange(Date: string, BookingSlots: Object, Shooters: string) {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: JSON.stringify({Date, BookingSlots, Shooters}),
  };

  return fetch(`${apiRoot}RangeLog/BookRange`, requestOptions)
      .then(handleResponse)
}

//Admin Endpoints
export async function getContactList() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Admin/GetContactList`, requestOptions).then(handleResponse);
}

export async function getUserLookups() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Admin/GetUserLookup`, requestOptions).then(handleResponse);
}

export async function getLogUserLookups() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Admin/GetLogUserLookup`, requestOptions).then(handleResponse);
}

export async function getAllRangeBookings() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Admin/GetRangeBookings`, requestOptions).then(handleResponse);
}

export async function getUserRenewalList(year:string, type:string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Admin/GetUserRenewalList?year=${year}&type=${type}`, requestOptions).then(handleResponse);
}

export async function adminDeleteRangeBooking(id: number) {
  const requestOptions = {
    method: "GET",
    headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
    //body: JSON.stringify({idList}),
};
  return await fetch(`${apiRoot}Admin/deleteRangeBooking?Id=${id}`, requestOptions).then(handleResponse);
}

//Member
export async function getTitleLookups(endPoint: string) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/${endPoint}`, requestOptions).then(handleResponse);
}

export async function getRegisteredMember(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetRegisteredMember?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getMemberDetails(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetMemberDetails?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getFACDetails(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetFACDetails?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getNRADetails(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetNRADetails?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getCOCDetails(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetCOCDetails?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getQualDetails(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetQualificationDetails?Id=`+Id, requestOptions).then(handleResponse);
}

export async function getRenewalRequired(Id:number) {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/MemberRenewalRequired?Id=`+Id, requestOptions).then(handleResponseString);
}

export async function getPendingApplicantLookup() {
  const requestOptions: RequestInit = {method: "GET", headers: authHeader()};
  return await fetch(`${apiRoot}Member/GetPendingApplicantLookup`, requestOptions).then(handleResponse);
}

//Update Member
export function postDataNoAuth(endPoint: string, body:string, Id:number): Promise<boolean> {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: body,
  };

  var url = `${apiRoot}Member/${endPoint}?Id=`+Id;

  return fetch(url, requestOptions)
      .then(handleResponse)
      .then((resp) => {
        var retVal = false;

          if(resp.status !== "FAILED")
            retVal = true;

          return retVal;
      });
}

export function postDataAuth(endPoint: string, body:string, Id:number): Promise<boolean> {
  const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
      body: body,
  };

  var url = `${apiRoot}Member/${endPoint}?Id=`+Id;

  return fetch(url, requestOptions)
      .then(handleResponse)
      .then((resp) => {
        var retVal = false;

          if(resp.status !== "FAILED")
            retVal = true;

          return retVal;
      });
}

