import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Copyright from "../components/Copright";
import image1 from "../images/Logo.jpg";
import {useOrientation} from "../hooks/useOrientation";
import {isDesktop} from "react-device-detect";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, CircularProgress} from "@mui/material";
import {authenticationService} from "../services/authentication-service";

const PasswordReset: FunctionComponent = () => {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }
  }, [navigate, from]);

  const handleOnClick = () => {
    navigate("/login");
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Enter a valid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);
      
      authenticationService.resetPassword(values.email).then(
        (response:any) => {
          setSubmitting(false);

          if(response.message.includes("Error"))
          {
            setStatus("Error: Have you renewed online?");
          }
          else
          {
            setStatus("Success Check Email");
          }
        },
        (error) => {
          setSubmitting(false);
          //setStatus(error instanceof Error ? error.message : error);
          setStatus("Unknown User Error");
        },
      );
      /*, (error) => {
        setSubmitting(false);

        if (!error) {
          setStatus("Success Check Email");
        } else  {
          setStatus("Unknown User Error");
        }
      })*/
    },
  });

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 4 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          {(orientation === "portrait" || isDesktop) && (
            <img
              src={image1}
              style={{
                maxWidth: "100%",
                maxHeight: "10%",
              }}
              alt="Langar RC"
            />
          )}

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Reset Password
            </Button>
            {status?.includes('Error') && <Alert severity="error">{status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            <Grid container><Grid item xs>
                <Link href="#" onClick={() => handleOnClick()} variant="body2">
                  Login
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" onClick={() => window.open("https://langarrc.co.uk/renewalbeta.aspx", "_blank")} variant="body2">
                  Renew Online
                </Link>
              </Grid></Grid>
            {submitting && <CircularProgress />}
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
    </ThemeProvider>
  );
};

export default PasswordReset;
