import React, {useState, FunctionComponent} from 'react';
import {getContactList} from "../services/user-service";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {authenticationService} from "../services/authentication-service";

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      <div>
        {/*<GridToolbarFilterButton />
        <GridToolbarDensitySelector />*/}
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

const ContactList: FunctionComponent = (props) => {
  
  interface IUser {
    Id: number;
    Forename: string;
    Surname: string;
    Initials: string;
    Email: string;
    MobileNo: string; 
    ContactNo: string;
    Position: string;
  };

  const [userData, setUserData] = useState<IUser[]>();
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/
  
  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getContactList().then(
      (data)=>
      {
        setRows(data);
        setUserData(data as IUser[]);
      });

  }, [navigate, from]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70, hide: false },
    { field: 'position', headerName: 'Position', width: 150},
    { field: 'forename', headerName: 'Forename', width: 100 },
    { field: 'surname', headerName: 'Surname', width: 100 },
    { field: 'initials', headerName: 'Initials', width: 75 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileNo', headerName: 'Mobile No', width: 130 },
    { field: 'contactNo', headerName: 'Contact No', width: 130 },
  ];

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<any[]>(userData??[]);
  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = userData?.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows??[]);
  };

  return (
    <div>
      <h1 style={{textAlign: "center"}}>Contact List</h1>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}

            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
          />
      </div>
    </div>
  );
};

export default ContactList;
