import {FunctionComponent} from "react";
import Link from "@mui/material/Link";
import Typography, {TypographyTypeMap} from "@mui/material/Typography";
import {DefaultComponentProps} from "@mui/material/OverridableComponent";

const Copyright: FunctionComponent<DefaultComponentProps<TypographyTypeMap>> = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="http://www.langarrc.org.uk/">
        Langarrc.org.uk
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
