import {useEffect, useState, useRef} from "react";

export function useOrientation() {
  const mediaMatcher = useRef(window.matchMedia("(orientation: portrait)"));
  const [portrait, setPortrait] = useState(mediaMatcher.current.matches);

  useEffect(() => {
    const orientationHandler = (e: MediaQueryListEvent) => {
      if (e.matches) setPortrait(e.matches);
    };
    const matcher = mediaMatcher.current;
    matcher.addEventListener("change", orientationHandler);
    return () => {
      matcher.removeEventListener("change", orientationHandler);
    };
  }, []);
  return portrait ? "portrait" : "landscape";
}
