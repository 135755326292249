import React, {FunctionComponent, useState, useRef, useEffect} from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import {authenticationService} from "../services/authentication-service";

interface MenuListCompositionProps {
  name: string;
}

const MenuListComposition: FunctionComponent<MenuListCompositionProps> = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent | React.MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node | null)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = (event: MouseEvent | TouchEvent | React.MouseEvent, data:string) => {
    event.preventDefault();
    navigate(data);

    if(data === "logout")
    {
      authenticationService.logout();
      navigate("/login");
    }
    else
    {
      navigate(data);

      if (anchorRef.current && anchorRef.current.contains(event.target as Node | null)) {
        return;
      }
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  function renderSwitch(param: string) {
    switch (param) {
      case "Menu":
        return (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}>
            <MenuItem href="/firearm-log-summary" onClick={(e) => { handleClick(e, "/firearm-log-summary") }}>
              View Firearm Log Summary
            </MenuItem>
            <MenuItem href="/firearm-log" onClick={(e) => { handleClick(e, "/firearm-log") }}>
              View Firearm Log
            </MenuItem>
            <MenuItem href="/firearms" onClick={(e) => { handleClick(e, "/firearms") }}>
              View Firearms
            </MenuItem>
            <MenuItem href="/add-firearm-log" onClick={(e) => { handleClick(e, "/add-firearm-log") }}>
              Add Firearm Log
            </MenuItem>
            <MenuItem href="/add-firearm" onClick={(e) => { handleClick(e, "/add-firearm") }}>
              Add Firearm
            </MenuItem>
          </MenuList>
        );
      case "AdminMenu":
        return (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}>
            <MenuItem href="/admin-contact-list" onClick={(e) => { handleClick(e, "/admin-contact-list") }}>
              View Contact List
            </MenuItem>
            <MenuItem href="/admin-member-renewals" onClick={(e) => { handleClick(e, "/admin-member-renewals") }}>
              View Renewals
            </MenuItem>
            <MenuItem href="/admin-view-range-booking" onClick={(e) => { handleClick(e, "/admin-view-range-booking") }}>
              View Range Bookings
            </MenuItem>
          </MenuList>
        );
        case "SuperAdminMenu":
        return (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}>
              <MenuItem href="/admin-contact-list" onClick={(e) => { handleClick(e, "/admin-contact-list") }}>
              View Contact List
            </MenuItem>
            <MenuItem href="/admin-member-renewals" onClick={(e) => { handleClick(e, "/admin-member-renewals") }}>
              View Renewals
            </MenuItem>
            <MenuItem href="/admin-view-range-booking" onClick={(e) => { handleClick(e, "/admin-view-range-booking") }}>
              View Range Bookings
            </MenuItem>
            <MenuItem href="/admin-firearm-log-summary" onClick={(e) => { handleClick(e, "/admin-firearm-log-summary") }}>
                View Member Firearm Log Summary
            </MenuItem>
            <MenuItem href="/admin-firearm-log" onClick={(e) => { handleClick(e, "/admin-firearm-log") }}>
                View Member Firearm Log
            </MenuItem>
            <MenuItem href="/admin-view-member" onClick={(e) => { handleClick(e, "/admin-view-member") }}>
                View Member
            </MenuItem>
          </MenuList>
        );
        case "DevMenu":
          return (
            <MenuList
              autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
              onKeyDown={handleListKeyDown}>
              <MenuItem href="/register" onClick={(e) => { handleClick(e, "/register") }}>
                Apply Online
              </MenuItem>
              <MenuItem href="/renewal" onClick={(e) => { handleClick(e, "/renewal") }}>
                Renew Online
              </MenuItem>
              <MenuItem href="/admin-view-new-appliations" onClick={(e) => { handleClick(e, "/admin-view-new-appliations") }}>
                View Applications
              </MenuItem>
              <MenuItem href="/payment" onClick={(e) => { handleClick(e, "/payment") }}>
                Payment
              </MenuItem>
              <MenuItem href="/add_event" onClick={(e) => { handleClick(e, "/add_event") }}>
                Add Event
              </MenuItem>
            </MenuList>
          );
      case "Range":
        //return <div></div>;
        return (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}>
            <MenuItem href="/book-range" onClick={(e) => { handleClick(e, "/book-range") }}>
              Book Range
            </MenuItem>
            <MenuItem href="/view-range-booking" onClick={(e) => { handleClick(e, "/view-range-booking") }}>
              View Range Bookings
            </MenuItem>
          </MenuList>
        );
      case "Account":
        return (
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}>
            <MenuItem href="/change-password" onClick={(e) => { handleClick(e, "/change-password") }}>
              Change Password
            </MenuItem>
            <MenuItem href="/renewal" onClick={(e) => { handleClick(e, "/renewal") }}>
                Renew Online
              </MenuItem>
            <MenuItem onClick={(e) => { handleClick(e, "logout") }}>
              Logout
            </MenuItem>
          </MenuList>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          style={{color: "#FFFFFF"}}
          variant="text"
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}>
          {props.children}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>{renderSwitch(props.name)}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

export default MenuListComposition;
