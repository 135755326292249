import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import RegisterPage from './RegisterPage';
import DetailsPage from './DetailsPage';
//import CompletePage from './CompletePage';
import Copyright from "../components/Copright";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useState} from 'react';
import {authenticationService} from "../services/authentication-service";
import {getCOCDetails, getFACDetails, getUserLookups, getMemberDetails, getNRADetails, getQualDetails, getRegisteredMember} from "../services/user-service";
//import Question from "./Question";
import FACDetailsPage from "./FACDetailsPage";
import NRADetailsPage from "./NRADetailsPage";
import COCDetailsPage from "./COCDetailsPage";
//import MembershipDetailsPage from "./MembershipDetailsPage";
import QualDetailsPage from "./QualDetailsPage";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const theme = createTheme();

function ViewMember(props: {page:string}) {
  interface ILookup {
    id: number;
    description: string;
  };
  
  const [userLookups, setUserLookups] = useState<ILookup[]>();

  const [registerData, setRegisterData] = useState<any>(null);
  const [detailsData, setDetailsData] = useState<any>(null);
  const [nraData, setNRAData] = useState<any>(null);
  const [facData, setFACData] = useState<any>(null);
  const [cocData, setCOCData] = useState<any>(null);
  const [qualData, setQualData] = useState<any>(null);
  const [page, setCurrentPage] = useState<string>("");
  const [prevPage, setPrevPage] = useState<string>("register");
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    memberId: Yup.number().required("memberId is required"),
  });

  const formik = useFormik({
    initialValues: {
      memberId: -1
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

    },
  });

  function showUserLookup() {
    return userLookups?.length && (<div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Member</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Member"
                value={formik.values.memberId}
                onChange={
                  value=>{
                    formik.setFieldValue('memberId', value.target.value); 
                    //setData(undefined);
                    //getProgressives(value.target.value.toString()).then((data)=>setData(data as IUser[]));
                    getRegisteredMember(Number(value.target.value)).then((rm_data:any)=>setRegisterData(rm_data));
                    getMemberDetails(Number(value.target.value)).then((rm_data:any)=>setDetailsData(rm_data));
                    getFACDetails(Number(value.target.value)).then((rm_data:any)=>setFACData(rm_data));
                    getNRADetails(Number(value.target.value)).then((rm_data:any)=>setNRAData(rm_data));
                    getCOCDetails(Number(value.target.value)).then((rm_data:any)=>setCOCData(rm_data));
                    getQualDetails(Number(value.target.value)).then((rm_data:any)=>setQualData(rm_data));
                    setPage(props.page);
                  }
                }
                error={formik.touched.memberId && Boolean(formik.errors.memberId)}
              >
                {
                  userLookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.memberId ? <div className='error'>{formik.errors.memberId}</div> : null}
            </FormControl><br/><br/>
          </div>);
  }

  function setPage(next:string)
  {
    switch(next)
    {
      case "register":
        setPrevPage("register");
        break;
      case "details":
        setPrevPage("register");
        break;
      case "fac_question":
        setPrevPage("details");
        break;
      case "fac_details":
        setPrevPage("fac_question");
        break;
      case "nra_question":
        setPrevPage("fac_question");
        break;
      case "nra_details":
        setPrevPage("nra_question");
        break;
      case "coc_question":
        setPrevPage("nra_question");
        break;
      case "coc_details":
        setPrevPage("coc_question");
        break;
      case "qual_details":
        setPrevPage("coc_question");
        break;
      case "membership_details":
        setPrevPage("qual_details");
        break;
      case "complete":
        setPrevPage("complete");
        break;
    }

    //setPrevPage(page);
    setCurrentPage(next);
  }

  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  React.useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getUserLookups().then((data)=>setUserLookups(data as ILookup[]));

    
    // eslint-disable-next-line
  }, [navigate, from]);

  function getPage()
  {
    switch(page)
    {
      case "register":
        return <RegisterPage prePopulate={registerData} readonly={true} ignorePassword={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("details")}}/>
      case "details":
        return <DetailsPage prePopulate={detailsData} readonly={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("fac_details")}} onBack={(event: any) => {setPage(prevPage)}}/>
      /*case "fac_question":
        return <Question question="Do you have a valid FAC?" onAnswer={(answer: any) => {
          if(answer)
            setPage("fac_details")
          else
            setPage("nra_question")
        }}  onBack={(event: any) => {setPage(prevPage)}}/>*/
      case "fac_details":
        return <FACDetailsPage prePopulate={facData} readonly={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("nra_details")}} onBack={(event: any) => {setPage(prevPage)}}/>
      /*case "nra_question":
        return <Question question="Are you a member of the NRA?" onAnswer={(answer: any) => {
          if(answer)
            setPage("nra_details")
          else
            setPage("coc_question")
        }} onBack={(event: any) => {setPage(prevPage)}}/>*/
      case "nra_details":
        return <NRADetailsPage prePopulate={nraData} readonly={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("coc_details")}} onBack={(event: any) => {setPage(prevPage)}}/>
      /*case "coc_question":
        return <Question question="Do you have a valid Card of Competence?" onAnswer={(answer: any) => {
          if(answer)
            setPage("coc_details")
          else
            setPage("qual_details")
        }} onBack={(event: any) => {setPage(prevPage)}}/>*/
      case "coc_details":
        return <COCDetailsPage prePopulate={cocData} readonly={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("qual_details")}} onBack={(event: any) => {setPage(prevPage)}}/>
      case "qual_details":
        return <QualDetailsPage prePopulate={qualData} readonly={true} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("details")}} onBack={(event: any) => {setPage(prevPage)}}/>
      /*case "membership_details":
        return <MembershipDetailsPage prePopulate={undefined} readonly={false} onSubmit={(event: any) => {return "";}} onSuccess={(event: any) => {setPage("complete")}} onBack={(event: any) => {setPage(prevPage)}}/>
      case "complete":
        return <CompletePage text={"Renewal Complete"} subtext={"Check email for payment details (if required)"} buttonText={""} buttonFunction={null}/>*/
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h5">
            Langar Rifle Club View Member
          </Typography>
          {showUserLookup()}
        {formik.values.memberId !== -1 && getPage()}
      </Box>
      <Copyright sx={{mt: 5}} />
      </Container>
    </ThemeProvider>
  );
};

export default ViewMember;
