import {useFormik} from "formik";
import React, {useState} from 'react';
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
//import {getTitleLookups} from "../services/user-service";
//import MobileDatePicker from "@mui/x-date-pickers/MobileDatePicker";
//import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Stack from '@mui/material/Stack';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getTitleLookups, postDataAuth } from "../services/user-service";
import { authenticationService } from "../services/authentication-service";
//import { getTitleLookups } from "../services/user-service";

function NRADetailsPage(props: {/*memberId: any, */prePopulate: any, readonly: boolean, onSubmit: any, onSuccess: any, onBack:any}) {
  const [submitting, setSubmitting] = useState(false);
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [status, setStatus] = useState<string | undefined>(undefined);

  const today = new Date();
  today.setHours(0, 0, 0, 0)

  const [initialValues, setinitialValues] = useState({
    nraNumber: "",
    nraMembershipType: "",
    nraExpiryDate: new Date(today.getDate()-1),
    disabled: props.readonly,
  });

  function onClick()
  {
    //props.onBack();
    props.onSuccess();
  }

  interface INRADetails {
    id: number;
    nraExpiryDate: Date,
    nraNumber: string,
    nraMembershipTypeId: number,
  };

  const validationSchema = Yup.object({
    nraNumber: Yup.string().required("Membership Number is required").min(3, "Minimum 3 digits long"),
    nraMembershipType: Yup.string().required("Membership Type is required"),
    nraExpiryDate: Yup.date()
    .min(today, "Date must be in the future")
    //.max(today, "Date must be in the future2")
    /*.test("expiryDate", "Should be in the future", function (value) {
      var val = differenceInDays(new Date(), new Date(value == undefined ? "12/31/2022" : value));
      return val > 0;
    }),*/
  });

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      nraNumber: initialValues.nraNumber,
      nraMembershipType: initialValues.nraMembershipType,
      nraExpiryDate: initialValues.nraExpiryDate,
      disabled: initialValues.disabled,
    },
    validationSchema: !props.readonly ? validationSchema : null,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(undefined);

      var error = props.onSubmit ? props.onSubmit() : "";

      if(error === "")
      {

        var userId = authenticationService.currentUserValue ? authenticationService.currentUserValue.id : -1;
          const req: INRADetails = {
            id: userId,
            nraExpiryDate: values.nraExpiryDate,
            nraMembershipTypeId: Number(values.nraMembershipType),
            nraNumber: values.nraNumber,
        };

        postDataAuth("AddMemberNRAData", JSON.stringify(req), Number(userId)).then((res: any)=>{

          setSubmitting(false);
  
          if(res)
          {
            formik.resetForm();
            //setStatus("Firearm Added.");
          }
          else
          {
            setStatus("Error please try again.");
          }
        });

        if(props.onSuccess)
          props.onSuccess();
      }
      else
      {
        setSubmitting(false);
        setStatus(error);
      }
    },
  });

  function setFormikValues(data: INRADetails, title_data: ILookup[])
  {
    if(data)
    {
      setValue(data.nraExpiryDate);
      setinitialValues({
        nraNumber: data.nraNumber,
        nraMembershipType: data.nraMembershipTypeId.toString(),
        nraExpiryDate: data.nraExpiryDate,
        disabled: props.readonly,
      });
    }
  }

  const [lookups, setLookups] = useState<ILookup[]>();
  interface ILookup {
    id: number;
    description: string;
  };

  React.useEffect(() => {
    getTitleLookups("GetNRALookups").then((data)=>{
      setLookups(data as ILookup[])

      if(props.prePopulate/* && props.memberId != null*/)
      {
        //props.prePopulate(props.memberId).then((rm_data:any)=>setFormikValues(rm_data as IRegisteredMember, data as ILookup[]));
        setFormikValues(props.prePopulate as INRADetails, data as ILookup[])
      }
    });
    // eslint-disable-next-line
  }, [props/*navigate, from*/]);

  return (
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography component="h1" variant="h6">
            NRA Details
          </Typography>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{mt: 3}}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="nraNumber"
                  label="`Membership Number"
                  name="nraNumber"
                  autoComplete="nraNumber"
                  disabled={formik.values.disabled}
                  value={formik.values.nraNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.nraNumber && Boolean(formik.errors.nraNumber)}
                  helperText={formik.touched.nraNumber && formik.errors.nraNumber}
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300}}>
              <InputLabel id="demo-simple-select-standard-label">Membership Type</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Membership Type"
                value={formik.values.nraMembershipType}
                //disabled={formik.values.disabled}
                onChange={
                  value=>{
                    formik.setFieldValue('nramembershipType', value.target.value); 
                    //setActivity(value.target.value);
                  }
                }
                error={formik.touched.nraMembershipType && Boolean(formik.errors.nraMembershipType)}
              >
                {
                  lookups?.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.nraMembershipType ? <div className='error'>{formik.errors.nraMembershipType}</div> : null}
            </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider 
              dateAdapter={AdapterDateFns} 
              //locale={enLocale}
              adapterLocale={enLocale}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Expiry Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                    formik.setFieldValue('nraExpiryDate', newValue); 
                  }}
                  onClose={() => {
                    //updateBookingSlots();
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} 
                  error={formik.touched.nraExpiryDate && Boolean(formik.errors.nraExpiryDate)}
                  helperText={formik.touched.nraExpiryDate && formik.errors.nraExpiryDate}/>}
                />
              </Stack>
            </LocalizationProvider>
            </Grid>  
            </Grid>
            {status && <div className={"alert alert-danger"}>{status}</div>}
            {submitting && <CircularProgress />}
            {!props.readonly && <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Next
            </Button>}
            <Button type="submit" fullWidth variant="contained" onClick={()=>{onClick()}} sx={{mt: 3, mb: 2}}>
              Next
            </Button>
          </Box>
        </Box>
  );
};

export default NRADetailsPage;
