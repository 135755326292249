import {BehaviorSubject} from "rxjs";
import {handleResponse} from "../helpers/handle-response";
import {authHeaderString} from "../helpers/auth-header";
import {createContext} from "react";

export interface User {
  id: number;
  username: string;
  forename: string,
  surname: string,
  position: string,
  accessLevel: string;
  token: string;
  expires: Date;
  universalExpiry: number;
}

const apiRoot = process.env.REACT_APP_API_BASE_URL;
console.log({apiRoot});
export const UserContext = createContext<User | null>(null);

const currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem("currentUser") as string));

export const authenticationService = {
    login,
    register,
    logout,
    resetPassword,
    changePassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {

        if(currentUserSubject.value?.expires)
        {
            console.log("got expiry date " + (currentUserSubject.value?.universalExpiry - new Date(Date.now()).getTime()));
            if((currentUserSubject.value?.universalExpiry - new Date(Date.now()).getTime()) <= 0)
            {
                logout();
                return null;
            }
        }

        return currentUserSubject.value;
    },
};

function login(username: string, password: string): Promise<User> {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({username, password}),
    };

    return fetch(`${apiRoot}Authentication/Authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);
      // TODO: should do some validation of the JSON to ensure it's valid
      return user;
    });
}

function resetPassword(username: string): Promise<unknown> {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({username, password:""}),
    };

    return fetch(`${apiRoot}Authentication/ResetPassword`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        });
    }

function changePassword(username: string, password: string): Promise<unknown> {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization":authHeaderString()},
        body: JSON.stringify({username, password}),
    };

    return fetch(`${apiRoot}Authentication/SetPassword`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        });
    }

// TODO: Improve type when we know what it'll be
function register(username: string, password: string): Promise<unknown> {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({username, password}),
    };

    return fetch(`${apiRoot}Home/Register`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);
}
