import {useFormik} from "formik";
import * as Yup from "yup";
import {useEffect, FunctionComponent, useState} from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useOrientation} from "../hooks/useOrientation";
import {/*useLocation,*/ useNavigate} from "react-router-dom";
import {Alert, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid} from "@mui/material";

//import MobileDatePicker from "@mui/lab/MobileDatePicker";
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {getFirearmsLookups, addFirearmLog, getLookups} from "../services/user-service";
import enLocale from 'date-fns/locale/en-GB';
import {authenticationService} from "../services/authentication-service";

const AddEvent: FunctionComponent = () => {
  
  //const [value, setValue] = React.useState(new Date());
  const [value, setValue] = React.useState<Date | null>(new Date());
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const navigate = useNavigate();
  //const location = useLocation();
  const from = "/"; /*location.state?.from?.path || "/";*/

  const [firearm, setFirearm] = React.useState('');
  const [range, setRange] = React.useState(-10);
  const [activity, setActivity] = React.useState('');

  const [firearmLookups, setFirearmLookups] = useState<IFirearmLogLookups>();

  interface IFirearmLogLookups {
      firearms: ILookup[];
      ranges: ILookup[];
      rangeActivities: ILookup[];
  };

  interface ILookup {
    id: number;
    description: string;
  };

  useEffect(() => {
    if (!authenticationService.currentUserValue) {
      navigate(from, {replace: true});
    }

    getFirearmsLookups().then((data)=>setFirearmLookups(data as IFirearmLogLookups));
  }, [navigate, from]);

  const validationSchema = Yup.object({
    firearm: Yup.string().required("Firearm is required"),
    range: Yup.string().required("Range is required"),
    activity: Yup.string().required("Activity is required"),
    roundCount: Yup.number().required("Round Count is required"),
  });

  const formik = useFormik({
    initialValues: {
      firearm: "",
      range: "",
      activity: "",
      customRange: "",
      roundCount: 0,
      ticketFee: 0,
      transactionFee: 0,
      declaration: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      setStatus(null);
      
      /*addFirearmLog(new Intl.DateTimeFormat('en-GB').format(new Date(value as Date)), Number(firearm), Number(range), Number(activity), values.roundCount, values.customRange).then((res: any)=>{

        setSubmitting(false);

        if(res)
        {
          if(formik.values.customRange !== "" && formik.values.range === "-2")
            getLookups("location").then((data)=>setFirearmLookups(data as IFirearmLogLookups));

          formik.resetForm();
          setRange(-10);
          setStatus("Firearm Log Added.");
        }
        else
        {
          setStatus("Add Error please try again.");
        }

      })*/
    },
  });

  function addPaymentOption()
  {
    return(<div><TextField
      margin="normal"
      required
      fullWidth
      name="roundCount"
      label="Max Tickets"
      type="roundCount"
      id="roundCount"
      autoComplete="current-password"
      value={formik.values.roundCount}
      onChange={formik.handleChange}
      error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
      helperText={formik.touched.roundCount && formik.errors.roundCount}
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="ticketFee"
      label="Ticket Fee (Pence)"
      type="ticketFee"
      id="ticketFee"
      value={formik.values.ticketFee}
      onChange={(e) => {    
        //formik.values.ticketFee = e.target.value;
        formik.handleChange(e);
        if(Number.isInteger(Number(e.target.value)))
        { 
          formik.values.transactionFee = Math.ceil((20 + Number(e.target.value) * 0.015) * 1.02);
          formik.setFieldValue('transactionFee', formik.values.transactionFee); 
        }
      }}
      error={formik.touched.ticketFee && Boolean(formik.errors.ticketFee)}
      helperText={formik.touched.ticketFee && formik.errors.ticketFee}
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="transactionFee"
      label="Online Transaction Fee (Pence)"
      type="transactionFee"
      id="transactionFee"
      value={formik.values.transactionFee}
      onChange={formik.handleChange}
      error={formik.touched.transactionFee && Boolean(formik.errors.transactionFee)}
      helperText={formik.touched.transactionFee && formik.errors.transactionFee}
    />
    <Grid item xs={12}>
      <FormControl  component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
          control={
            <Checkbox onChange={formik.handleChange} name="declaration" id="declaration"/>
          }
          label="Allow Online Payment"
        />
      </FormGroup>
      <FormHelperText></FormHelperText>
      {formik.errors.declaration ? <div className='error'>{formik.errors.declaration}</div> : null}
      </FormControl>
    </Grid></div>);
  }

  const theme = createTheme();
  const orientation = useOrientation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: orientation === "portrait" ? 1 : 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>

          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
            <h1 style={{textAlign: "center"}}>Add Event</h1>
            <LocalizationProvider 
            dateAdapter={AdapterDateFns} 
            //locale={enLocale}
            adapterLocale={enLocale}
            >
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Start Date"
                  value={value}
                  onChange={(newValue) => {
                    newValue && setValue(newValue);
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <br></br>
            <LocalizationProvider 
            dateAdapter={AdapterDateFns} 
            //locale={enLocale}
            adapterLocale={enLocale}
            >
              <Stack spacing={3}>
                <MobileDatePicker
                  label="End Date"
                  value={value}
                  onChange={(newValue) => {
                    newValue && setValue(newValue);
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <br></br>
            <LocalizationProvider 
            dateAdapter={AdapterDateFns} 
            //locale={enLocale}
            adapterLocale={enLocale}
            >
              <Stack spacing={3}>
                <MobileDatePicker
                  label="Deadline"
                  value={value}
                  onChange={(newValue) => {
                    newValue && setValue(newValue);
                  }}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
            <TextField
              margin="normal"
              required
              fullWidth
              name="roundCount"
              label="Title"
              type="roundCount"
              id="roundCount"
              autoComplete="current-password"
              value={formik.values.roundCount}
              onChange={formik.handleChange}
              error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
              helperText={formik.touched.roundCount && formik.errors.roundCount}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              multiline
              rows={4}
              name="roundCount"
              label="Description"
              type="roundCount"
              id="roundCount"
              autoComplete="current-password"
              value={formik.values.roundCount}
              onChange={formik.handleChange}
              error={formik.touched.roundCount && Boolean(formik.errors.roundCount)}
              helperText={formik.touched.roundCount && formik.errors.roundCount}
            />
            <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="demo-simple-select-standard-label">Firearm</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Location"
                value={formik.values.firearm}
                onChange={
                  value=>{
                    formik.setFieldValue('firearm', value.target.value); 
                    setFirearm(value.target.value);
                  }
                }
                error={formik.touched.firearm && Boolean(formik.errors.firearm)}
              >
                {
                  firearmLookups?.firearms.map((row) => (
                    <MenuItem value={row.id} key={row.description}>{row.description}</MenuItem>
                  ))
                }
              </Select>
              {formik.errors.firearm ? <div className='error'>{formik.errors.firearm}</div> : null}
            </FormControl>
            <Grid item xs={12}>
              <FormControl  component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                  control={
                    <Checkbox onChange={formik.handleChange} name="declaration" id="declaration"/>
                  }
                  label="Response Required"
                />
              </FormGroup>
              <FormHelperText></FormHelperText>
              {formik.errors.declaration ? <div className='error'>{formik.errors.declaration}</div> : null}
              </FormControl>
            </Grid> 
            <Grid item xs={12}>
              <FormControl  component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                  control={
                    <Checkbox onChange={formik.handleChange} name="declaration" id="declaration"/>
                  }
                  label="Add Tickets"
                />
              </FormGroup>
              <FormHelperText></FormHelperText>
              {formik.errors.declaration ? <div className='error'>{formik.errors.declaration}</div> : null}
              </FormControl>
            </Grid> 
            {formik.values.declaration && addPaymentOption()}
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Add
            </Button>
            {status?.includes('Error') && <Alert severity="error">Error: {status}</Alert>}
            {status && !status.includes('Error') && <Alert severity="success">Success: {status}</Alert>}
            {submitting && <CircularProgress />}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AddEvent;
